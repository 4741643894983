/* eslint-disable @typescript-eslint/no-explicit-any */
import * as api from '$lib/api';
import {
  type ContributorOnlyProfile,
  type ContributorSearchResult,
  type PaginationResponse,
  Profile,
  type Project,
  UserDataOnlyProfile
} from '$lib/types';
import { createMutation, createQuery, useQueryClient } from '@tanstack/svelte-query';
import { QUERY_KEYS } from '$lib/queries/keys';
import { ApiError } from '$lib/api/errors';
import {
  getEnvName,
  ProjectContactStatus,
  tenMinutesInMs,
  thirtyMinutesInMs,
  UserType
} from '$lib/constants';
import { toQueryString } from '$lib/utils';
import algoliasearch from 'algoliasearch/lite';
import { page } from '$app/stores';

const contributorProfileUrl = '/contributors/profile';

export const getMyContributorProfile = (enabled = true) =>
  createQuery({
    queryKey: QUERY_KEYS.CONTRIBUTOR_MY_PROFILE,
    queryFn: async (): Promise<(ContributorOnlyProfile & { user_type: UserType }) | null> => {
      try {
        return api.get<ContributorOnlyProfile & { user_type: UserType }>(contributorProfileUrl);
      } catch (e: any) {
        if (e.status === 404) {
          return null;
        }
        throw e;
      }
    },
    gcTime: tenMinutesInMs,
    staleTime: tenMinutesInMs,
    enabled
  });

export const putMyContributorProfileQuery = () => {
  const queryClient = useQueryClient();
  return createMutation<
    { contributor_ref?: string; user_ref: string },
    unknown,
    UserDataOnlyProfile | Profile
  >({
    mutationFn: (profile: UserDataOnlyProfile | Profile) =>
      api.put(contributorProfileUrl, { profile }),
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.CONTRIBUTOR_MY_PROFILE });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.USERS_ME_USER_DATA });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.CONTRIBUTOR_PROFILE });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PROFILE_USERS });
    },
    mutationKey: QUERY_KEYS.CONTRIBUTOR_MY_PROFILE
  });
};

export const putMyContributorVisibilityQuery = () => {
  const queryClient = useQueryClient();
  return createMutation<void, unknown, boolean>({
    mutationFn: (visible: boolean) => api.put(`${contributorProfileUrl}/visibility`, { visible }),
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.CONTRIBUTOR_MY_PROFILE });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.USERS_ME_USER_DATA });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.CONTRIBUTOR_PROFILE });
    },
    mutationKey: QUERY_KEYS.CONTRIBUTOR_MY_PROFILE
  });
};

export const getContributorQuery = (ref: string) =>
  createQuery<unknown, ApiError, { user__ref: string }>({
    queryKey: [...QUERY_KEYS.CONTRIBUTOR_PROFILE, ref],
    queryFn: async (): Promise<{ user__ref: string }> =>
      api.get<{
        user__ref: string;
      }>(`${contributorProfileUrl}/${ref}`)
  });

type ProjectContributorMutationData = {
  status: ProjectContactStatus;
  decline_reason?: string;
  contributorRef?: string;
};
export const changeContributorProjectStatus = (
  projectRef: string,
  contributorRef: string,
  onSuccess?: () => void
) => {
  const queryClient = useQueryClient();
  return createMutation<void, Error | ApiError, ProjectContributorMutationData>({
    mutationFn: async (payload: ProjectContributorMutationData) =>
      await api.post(
        `/engagements/projects/${projectRef}/contributors/${
          payload.contributorRef ?? contributorRef
        }/status-change`,
        payload
      ),
    onSettled: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [...QUERY_KEYS.PROJECTS_DETAILS, projectRef]
        }),
        queryClient.invalidateQueries({
          queryKey: [...QUERY_KEYS.PROJECTS_SHORTLIST, projectRef]
        }),
        queryClient.invalidateQueries({
          queryKey: [...QUERY_KEYS.CONTRIBUTOR_PROFILE, contributorRef]
        })
      ]);
    },
    onSuccess,
    mutationKey: [...QUERY_KEYS.PROJECTS_CONTRIBUTORS, projectRef, contributorRef]
  });
};

export const sendProjectContributorMessage = (contributorRef: string) => {
  const queryClient = useQueryClient();
  return createMutation({
    mutationKey: QUERY_KEYS.INBOX_SEND_MESSAGE,
    mutationFn: async (content: string) =>
      api.post<void>(`/contributors/${contributorRef}/messages`, {
        content
      }),
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.INBOX_CONVERSATIONS });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.INBOX_USER_MESSAGES });
    }
  });
};

export const deleteProjectContributorMutation = (projectRef: string) => {
  const queryClient = useQueryClient();
  return createMutation<void, unknown, string>({
    mutationFn: async (contributorRef: string) => {
      await api.del<Project>(`/engagements/projects/${projectRef}/contributors/${contributorRef}`);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.PROJECTS
      });
    },
    mutationKey: [...QUERY_KEYS.PROJECTS_CONTRIBUTORS, projectRef]
  });
};

export const putProjectContributor = (projectRef?: string) => {
  const queryClient = useQueryClient();
  return createMutation({
    mutationFn: async ({
      ref,
      status = 'shortlisted',
      projectRefOverride
    }: {
      ref: string;
      status?: ProjectContactStatus;
      projectRefOverride?: string;
    }) => {
      const projectRefToUse = projectRefOverride ?? projectRef;
      await api.put(`/engagements/projects/${projectRefToUse}/contributors/${ref}`, { status });
    },
    onSettled: async () => {
      console.log('invalidating');
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [...QUERY_KEYS.PROJECTS_DETAILS, projectRef]
        }),
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.PROJECTS_SHORTLIST
        }),
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.PROJECTS_CONTRIBUTORS
        }),
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.PROFILE_USERS
        })
      ]);
    },
    mutationKey: [...QUERY_KEYS.PROJECTS_CONTRIBUTORS, projectRef]
  });
};

export const getLatestContributors = (enabled: boolean) => {
  return createQuery({
    queryKey: QUERY_KEYS.CONTRIBUTOR_LATEST_PROFILES,
    queryFn: async () => api.get<ContributorSearchResult[]>(`/contributors/latest`),
    staleTime: thirtyMinutesInMs,
    gcTime: thirtyMinutesInMs,
    enabled
  });
};
