import { EmailPreferences } from '$lib/queries/settings';

export const EmailNotificationGroupings = {
  projectEvents: new Set([
    'submission_received',
    'contributor_accepted',
    'contributor_declined',
    'owner_invited',
    'owner_approved',
    'owner_declined',
    'reminder__recommended_open_project'
  ]),
  reminders: new Set(['project_deadline_approaching', 'reminder__project_empty'])
};

export const EmailNotificationKeyContent: Record<
  keyof EmailPreferences,
  { title: string; explanation: string }
> = {
  submission_received: {
    title: 'Submission received',
    explanation: 'A contributor submitted a response to one of your projects.'
  },
  contributor_accepted: {
    title: 'Contributor accepted',
    explanation: 'A contributor accepted one of your project invites.'
  },
  contributor_declined: {
    title: 'Contributor declined',
    explanation: 'A contributor declined your invitation.'
  },
  owner_invited: {
    title: 'Project invite',
    explanation: 'Someone invited you to contribute to a project.'
  },
  owner_approved: {
    title: 'Submission approved',
    explanation: 'One of your submissions was approved by the project owner.'
  },
  owner_declined: {
    title: 'Submission declined',
    explanation: 'One of your submissions was approved by the project owner.'
  },
  unread_message: { title: 'Unread message', explanation: 'You have an unread message.' },
  project_deadline_approaching: {
    title: 'Project deadline approaching',
    explanation: "One of your projects has an approaching deadline and isn't yet complete."
  },
  reminder__project_empty: {
    title: 'Empty project reminder',
    explanation: "You've created a project but forgotten to invite anyone to it."
  },
  reminder__recommended_open_project: {
    title: 'Recommended public project',
    explanation: 'A new public project has been added that we think might be a good fit for you.'
  }
};
