<script lang="ts">
  import SERP from '$lib/components/Search/SERP.svelte';
  import PageTitle from '$lib/components/PageTitle.svelte';
  import { capitalise } from '$lib/utils';
  import { getProjectQuery, getProjectRecommendationsQuery } from '$lib/queries/projects';
  import FieldBlock from '$lib/components/FieldBlock.svelte';
  import ErrorChunk from '$lib/components/ErrorChunk.svelte';
  import Button, { Icon, Label } from '@smui/button';
  import Dialog, { Actions, Content, Title } from '@smui/dialog';
  import InteractButton from '$lib/components/Projects/InteractButton.svelte';
  import Avatar from '$lib/components/Images/Avatar.svelte';
  import { toEmploymentDescription } from '$lib/components/Profiles/utils';
  import Skeleton from '$lib/components/Skeleton.svelte';

  export let data;
  let open = window.location.search?.includes('first_created=true');
  const projectRef = data.params.ref;
  const projectQuery = getProjectQuery(projectRef);
  const recommendationsQuery = getProjectRecommendationsQuery({
    ref: projectRef
  });
</script>

<PageTitle name="Search" />

<SERP {projectRef}>
  <FieldBlock loading={$projectQuery.isLoading} slot="heading">
    {#if $projectQuery.isError}
      <ErrorChunk />
    {:else}
      <div class="row">
        <div class="col-md-8">
          <h3>
            <a href="/projects/{projectRef}" class="title-link"
              >Project: {$projectQuery.data?.name ?? ''}</a
            >
          </h3>
        </div>
        <div class="col-md-4 d-flex md-justify-content-end mb-2">
          <Button
            variant="raised"
            on:click={() => (open = true)}
            style="height: auto; min-height: 2.5em;"
          >
            <Icon class="material-icons" style="margin: 0; margin-right: .5em">insights</Icon>
            Contributor Recommendations
          </Button>
        </div>
      </div>
      <p>
        <strong>Outlet:</strong>
        {$projectQuery.data?.outlet ?? ''}<br />
        <strong>Type:</strong>
        {$projectQuery.data?.type ? capitalise($projectQuery.data?.type) : ''}
      </p>
      <h5>How Antiquoted projects work</h5>
      <ul>
        <li>
          <strong>Projects are only visible to the people you invite</strong> to contribute to them.
        </li>
        <li>Search and filter our list of contributors and hit “Profile” to find out more.</li>
        <li>
          If you aren't ready to invite contributors, you can shortlist people and invite them at a
          later date. Contributors are only notified when you invite them and not when they are
          shortlisted.
        </li>
      </ul>
    {/if}
  </FieldBlock>
</SERP>

<Dialog
  bind:open
  aria-labelledby="Decline reason"
  aria-describedby="Give a reason why you're declining this request"
  surface$style="width: calc(100vh - 150px); max-width: 95vw;"
>
  <Title>Contributor recommendations</Title>
  <Content>
    <!--  ToDo: Add a nice error state here to go along w/ heavy backend rate limiting -->
    {#if $recommendationsQuery.isLoading}
      <p>
        Bear with us, this can take ~30 seconds. We're having a look for some matches for your
        project...
      </p>
      {#each [1, 2, 3] as i (i)}
        <div class="d-flex justify-content-between align-items-start gap-2">
          <div class="d-flex align-items-start gap-2" style="width:77%">
            <Skeleton style="display: inline-block; width: 30%; height: 6em" />
            <div style="display: inline-block; width: 65%">
              <Skeleton />
              <Skeleton />
            </div>
          </div>
          <Skeleton class="gap-4" style="display: inline-block; width: 20%; height: 2.5em" />
        </div>
      {/each}
    {/if}
    {#if $recommendationsQuery.data?.length}
      <p class="mt-2 mb-5">
        Here are some profiles that might be a good fit based on your project description.
        Alternatively you can search our contributor directory instead:
      </p>
    {/if}
    {#each $recommendationsQuery.data || [] as recommendation (recommendation.ref)}
      <div class="d-flex flex-row align-items-start justify-content-between mt-3 mb-4 gap-3">
        <a
          href="/projects/{projectRef}/contributors/{recommendation.ref}"
          class="profile d-flex align-items-start gap-3"
        >
          <Avatar src={recommendation.avatar_url} hasAvatar={!!recommendation.avatar_url} />
          <p class="mb-0 pt-1">
            {recommendation.first_name}
            {recommendation.last_name} <br />
            {toEmploymentDescription(recommendation)}
          </p>
        </a>

        <div class="pt-1">
          <InteractButton
            {projectRef}
            contributorRef={recommendation.ref}
            contributionStatus={recommendation.project_contributor_status}
            style="width:100%"
          />
        </div>
      </div>
    {/each}
    {#if $recommendationsQuery.error}
      {#if $recommendationsQuery.error?.status < 500}
        <p>
          {$recommendationsQuery.error?.toToastMessage()}
        </p>
      {:else}
        <p>
          Oops, looks like our magic recommendation box isn't working right now. Please check back
          later and we'll give it another go. In the meantime have a search for some from our
          contributor directory instead.
        </p>
      {/if}
    {/if}
    {#if $recommendationsQuery.data?.length === 0}
      <p>
        Sorry, we couldn't find any obvious contributor matches based on your description. Have a
        search yourself through our contributor directory instead - your eyes are probably way
        better than ours anyway.
      </p>
    {/if}
  </Content>
  <Actions>
    <Button action="close">
      <Label>Search for more</Label>
    </Button>
  </Actions>
</Dialog>

<style>
  .title-link,
  a.profile {
    text-decoration: none;
    color: inherit;

    & :hover {
      text-decoration: underline;
    }
  }

  .title-link:hover {
    text-decoration: underline;
    color: rgb(121, 40, 202);
  }
</style>
