import type { UserProfile } from '$lib/queries/profiles';
import { getFieldInfo } from '$lib/queries/common';
import {
  ContributorRecommendationResult,
  ContributorSearchResult,
  FieldInfo,
  UserData
} from '$lib/types';

export const toEmploymentDescription = (
  profile: UserProfile | UserData | ContributorSearchResult | ContributorRecommendationResult
) => {
  const response = [];
  if (profile.job_title) {
    response.push(profile.job_title);
    if (profile.company_name) {
      response.push(`at ${profile.company_name}`);
    }
  } else if (profile.company_name) {
    response.push(`Works at ${profile.company_name}`);
  }
  return response.join(' ');
};

export const toExtras = (
  profile?: UserProfile | UserData | ContributorSearchResult | null,
  fieldInfo?: FieldInfo | null,
  companySizes?: Record<string, string>
): string => {
  if (!profile || !fieldInfo || !companySizes) {
    return '';
  }
  let response: string[] = [toEmploymentDescription(profile)];

  if ('company_size' in profile && profile.company_size) {
    response.push(`(Company size: ${companySizes[profile.company_size]})`);
  }

  if (profile.country) {
    response.push(
      `located in ${fieldInfo.countries[profile.country]}${
        profile.city ? ', ' + profile.city : ''
      } `
    );
  }
  return response.join(' ');
};
