<script lang="ts">
  import SERP from '$lib/components/Search/SERP.svelte';
  import PageTitle from '$lib/components/PageTitle.svelte';
  import FieldBlock from '$lib/components/FieldBlock.svelte';
</script>

<PageTitle name="Search" />

<SERP>
  <FieldBlock loading={false} slot="heading">
    <h3>How Antiquoted projects work</h3>
    <ul>
      <li>
        <strong>Projects are only visible to the people you invite</strong> to contribute to them.
      </li>
      <li>Search and filter our list of contributors and hit “Profile” to find out more.</li>
      <li>
        If you aren't ready to invite contributors, you can shortlist people and invite them at a
        later date. Contributors are only notified when you invite them and not when they are
        shortlisted.
      </li>
    </ul>
  </FieldBlock>
</SERP>
