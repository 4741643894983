import { createMutation, createQuery, useQueryClient } from '@tanstack/svelte-query';
import { ApiError } from '$lib/api/errors';
import { QUERY_KEYS } from '$lib/queries/keys';
import * as api from '$lib/api';
import { Social, type StandardPaginationParams } from '$lib/types';
import { ProjectContactStatus, ProjectStatus } from '$lib/constants';
import { toQueryString } from '$lib/utils';

const userProfileUrl = '/profiles/users';

export type UserProfile = {
  ref: string;
  contributor_ref?: string;
  first_name: string;
  last_name: string;
  user_type: 'both' | 'contributor' | 'media_person';
  pronouns?: string;
  country?: string;
  city?: string;
  job_title?: string;
  company_name?: string;
  company_size?: string;
  years_of_experience?: string;
  note?: string;

  identifies_as: string[];
  contribution_types: string[];
  talking_points?: string;
  expertise: string[];
  contributor_experience: string[];
  socials: Social[];
  visible: boolean;
  can_message: boolean;
};

export const getUserProfileQuery = (ref: string) =>
  createQuery<unknown, Error | ApiError, UserProfile>({
    queryKey: [...QUERY_KEYS.PROFILE_USERS, ref],
    queryFn: async (): Promise<UserProfile> => api.get<UserProfile>(`${userProfileUrl}/${ref}`)
  });

export const getUserProfileAvatarQuery = (ref: string) =>
  createQuery({
    queryKey: [...QUERY_KEYS.PROFILE_USERS, ref, 'avatar'],
    queryFn: async (): Promise<string | null> => {
      try {
        const { url } = await api.get<{ url: string }>(`${userProfileUrl}/${ref}/avatar`);
        return url;
      } catch (e: any) {
        if (e.status === 404) {
          return null;
        }
        throw e;
      }
    }
  });

export const putUserProfileNoteMutation = (ref: string) => {
  const queryClient = useQueryClient();
  return createMutation<void, Error | ApiError, string>({
    mutationFn: async (note: string) => {
      return api.put<void>(`${userProfileUrl}/${ref}/note`, { note });
    },
    onSuccess: async () =>
      queryClient.invalidateQueries({
        queryKey: [...QUERY_KEYS.PROFILE_USERS, ref]
      })
  });
};

type ProfileHistory = {
  user_type: 'media_person' | 'contributor';
  project_contributor__ref: string;
  project__ref: string;
  project__name: string;
  project__type: string;
  project__status: ProjectStatus;
  status: ProjectContactStatus;
};
export const getProfileHistory = (
  userRef: string,
  { limit, page }: StandardPaginationParams,
  enabled: boolean = true
) =>
  createQuery({
    queryKey: [...QUERY_KEYS.PROFILE_HISTORY, userRef, { limit, page }],
    queryFn: async () => {
      const qs = toQueryString({
        limit: limit.toString(),
        page: page.toString()
      });
      return api.get<{
        items: ProfileHistory[];
        total_count: number;
      }>(`${userProfileUrl}/${userRef}/history?${qs}`);
    },
    enabled
  });
