<script lang="ts">
  import { page } from '$app/stores';
  import * as auth from '$lib/auth';
  import { ApiError } from '$lib/api/errors';
  import { capitalise, nextMonthName, toReadableDate } from '$lib/utils';
  import loadingGif from '$lib/img/loading.gif';
  import { showPaywallModal } from '$lib/components/WarningModal/stores';
  import FieldBlock from '$lib/components/FieldBlock.svelte';
  import { tryRefresh } from '$lib/api';
  import {
    cancelSubscriptionMutation,
    pollSubscriptionQuery,
    setSubscriptionSessionIdMutation
  } from '$lib/queries/subscription';
  import ErrorChunk from '$lib/components/ErrorChunk.svelte';
  import Button from '@smui/button';
  import { goto } from '$app/navigation';
  import { onMount } from 'svelte';
  import { SUPPORT_EMAIL } from '$lib/constants';
  import { getProjectsStatisticsQuery } from '$lib/queries/projects';
  import LoadingButton from '$lib/components/LoadingButton.svelte';

  export let openCancelModal: () => void;

  $: paymentCancelled = $page.url?.searchParams.get('cancelled') === 'true';
  $: returnLink = $page.url?.searchParams.get('returnRoute');
  $: if (paymentCancelled && returnLink) {
    goto(returnLink);
  }
  const enabled = true;

  const subscriptionQuery = pollSubscriptionQuery(enabled);
  const projectStatisticsQuery = getProjectsStatisticsQuery(!auth.isUnverifiedUser());
  const cancelAndOpenPaywallModalMutation = cancelSubscriptionMutation({
    onSuccess: () => {
      showPaywallModal();
    }
  });

  const setSubscriptionSessionId = setSubscriptionSessionIdMutation({
    onSuccess: () => {
      $page.url.searchParams.delete('sessionId');
      goto(`?${$page.url.searchParams.toString()}`);
    }
  });

  // Trigger a refresh if the user is premium but that scope isn't clear in their auth token.
  $: if (
    $subscriptionQuery.data?.status === 'active' &&
    !auth.isPremiumUser($subscriptionQuery.data?.status)
  ) {
    tryRefresh(auth.getSessionToken()).then(() => {
      window.location.reload();
    });
  }
  $: error = $setSubscriptionSessionId.error || $subscriptionQuery.error;

  onMount(() => {
    const sessionId = $page.url.searchParams.get('sessionId');
    if (sessionId) {
      $setSubscriptionSessionId.mutate(sessionId);
    }
  });
</script>

{#if enabled && !auth.isUnverifiedUser()}
  <FieldBlock
    loading={$subscriptionQuery.isLoading ||
      $setSubscriptionSessionId.isPending ||
      $projectStatisticsQuery.isLoading}
  >
    {#if error && (!(error instanceof ApiError) || error.status !== 404)}
      <ErrorChunk />
    {:else}
      <h4>
        Subscription status: {$subscriptionQuery.data
          ? capitalise($subscriptionQuery.data?.status)
          : 'Free'}
      </h4>
      {#if $subscriptionQuery.data?.status === 'active'}
        <p>Next payment is due on {toReadableDate($subscriptionQuery.data.expiry_date)}</p>
        {#if returnLink}
          <Button tag="a" href={returnLink}>Back to where we were</Button>
        {/if}
        <Button on:click={openCancelModal}>Cancel</Button>
      {:else if $subscriptionQuery.data?.status === 'expired'}
        <p>You don't have access to any premium features right now.</p>
        <p>
          {$projectStatisticsQuery.data?.invite_count_this_month} / 3 free invites used this month. Your
          invite allowance renews on the 1st of {nextMonthName()}.
        </p>
        <LoadingButton
          loading={$cancelAndOpenPaywallModalMutation.isPending}
          on:click={() => $cancelAndOpenPaywallModalMutation.mutate()}>Upgrade</LoadingButton
        >
        <LoadingButton
          loading={$cancelAndOpenPaywallModalMutation.isPending}
          on:click={openCancelModal}>Cancel</LoadingButton
        >
      {:else if $subscriptionQuery.data?.status === 'pending'}
        <img src={loadingGif} alt="A loading indicator" />
      {:else if $subscriptionQuery.data?.status === 'cancelled'}
        <p>
          Your premium features will expire at: {toReadableDate(
            $subscriptionQuery.data.expiry_date
          )}
        </p>
        <p>
          If you want to re-start your subscription you can do it after this date, or alternatively
          reach out to <a href="mailto:{SUPPORT_EMAIL}">support</a>.
        </p>
      {:else}
        <p>You don't have access to any premium features right now.</p>
        <p>
          {$projectStatisticsQuery.data?.invite_count_this_month} / 3 free invites used this month. Your
          invite allowance renews on the 1st of {nextMonthName()}.
        </p>
        <Button on:click={() => showPaywallModal()}>Upgrade</Button>
      {/if}
    {/if}
  </FieldBlock>
{/if}
