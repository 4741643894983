<script lang="ts">
  import Card, { Content } from '@smui/card';
  import * as auth from '$lib/auth';
  import PurpleWrapper from '$lib/components/PurpleWrapper.svelte';
  import FAQ from './FAQ.svelte';
  import MyDataWidgets from './MyDataWidgets.svelte';
  import AnonymousWidgets from './AnonymousWidgets.svelte';
  import PublicProjects from './PublicProjects.svelte';
  import { getMyUserData } from '$lib/queries/users';
  import LatestContributors from './LatestContributors.svelte';

  $: myUserData = getMyUserData(true, auth.isLoggedIn());
  $: userType = $myUserData.data?.user_type;
  const contributorUserTypes = new Set<string | undefined>(['contributor', 'both']);
  const mediaUserTypes = new Set(['media_person', 'both']);
</script>

<svelte:head>
  <title>Antiquoted | Dashboard</title>
</svelte:head>

<PurpleWrapper>
  <div class="container text-center">
    <div class="row pt-5">
      {#if auth.isLoggedIn()}
        <MyDataWidgets />
      {:else}
        <AnonymousWidgets />
      {/if}
    </div>
  </div>
</PurpleWrapper>

{#if auth.isLoggedIn()}
  <div class="container-fluid pb-5" style="background-color: #f8f9fa">
    <div class="container text-center">
      {#if !userType || mediaUserTypes.has(userType)}
        <div class="row pt-5">
          <div class="col">
            <h2 class="mb-5">Latest Contributors</h2>
            <LatestContributors />
          </div>
        </div>
      {/if}
      {#if auth.isLoggedIn() && auth.canSeeProjectManagement() && contributorUserTypes.has(userType)}
        <div class="row pt-5">
          <div class="col">
            <h2 class="pb-3 pt-2 mb-4">Latest Public Projects</h2>
            <Card>
              <PublicProjects />
            </Card>
          </div>
        </div>
      {/if}
    </div>
  </div>
{/if}

<div class="container-fluid pb-5" style="background-color: #f8f9fa">
  <div class="container text-center">
    <div class="row pt-5">
      <div class="col">
        <h2 class="mb-5">How Antiquoted works</h2>
        <div class="row">
          <div class="col-md-4 mb-3">
            <Card>
              <Content>
                <div class="avatar">1</div>
                <h5>A media project is created</h5>
                <p>
                  Media requests can be public or private on Antiquoted. The media person can search
                  for contributors with the right expertise and invite them to contribute to
                  respond.
                </p>
              </Content>
            </Card>
          </div>
          <div class="col-md-4 mb-3">
            <Card>
              <Content>
                <div class="avatar">2</div>
                <h5>A contributor receives a request</h5>
                <p>
                  The contributor will get an email asking them to either accept or decline each
                  media request. Use the messaging function to follow up if you have questions.
                </p>
              </Content>
            </Card>
          </div>
          <div class="col-md-4">
            <Card>
              <Content>
                <div class="avatar">3</div>
                <h5>A response is given</h5>
                <p>
                  If a contributor responds to a project, the media person will be able to see their
                  profile and response.
                </p>
              </Content>
            </Card>
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-5">
      <div class="col">
        <h2 class="mb-5">FAQs</h2>
        <FAQ />
      </div>
    </div>
  </div>
</div>

<style>
  .avatar {
    overflow: hidden;
    background-color: #e0e0e0;
    color: rgb(121, 40, 202);
    font-weight: bold;
    width: 85px;
    height: 85px;
    margin: 0.5em auto 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2em;
    padding: 0;
  }
</style>
