export const QUERY_KEYS = {
  COMMON_FIELD_INFO: ['common', 'field_info'],

  // Contacts
  CONTACTS_ALL: ['contacts', 'all'],
  CONTACTS_DETAILS: ['contacts', 'detail'],

  // Content
  CONTENT_FAQS: ['content', 'faqs'],

  // Contributions
  CONTRIBUTIONS_DETAILS: ['contributions', 'details'],
  CONTRIBUTIONS_PENDING: ['contributions', 'pending'],

  // Contributors
  CONTRIBUTOR_MY_PROFILE: ['contributors', 'me'],
  CONTRIBUTOR_PROFILE: ['contributors', 'profile'],
  CONTRIBUTOR_LATEST_PROFILES: ['contributors', 'latest', 'profiles'],

  // Messaging
  INBOX_CONVERSATIONS: ['inbox', 'conversations'],
  INBOX_USER_MESSAGES: ['inbox', 'user-messages'],
  INBOX_SEND_MESSAGE: ['inbox', 'send-message'],
  INBOX_SEND_BULK_MESSAGE: ['inbox', 'broadcast-project-message'],
  INBOX_UNREAD_MESSAGE_COUNT: ['inbox', 'unread', 'count'],

  // Invitations
  INVITATION_DETAILS: ['invitations', 'ref'],

  // Notifications
  NOTIFICATIONS: ['notifications'],
  NOTIFICATIONS_DETAIL: ['notifications', 'detail'],
  NOTIFICATIONS_COUNT: ['notifications', 'count'],

  // Profiles
  PROFILE_HISTORY: ['profile', 'history'],
  PROFILE_USERS: ['profile', 'users'],

  // Project
  PROJECTS: ['projects'],
  PROJECTS_ALL: ['projects', 'all'],
  PROJECTS_AVAILABLE: ['projects', 'available'],
  PROJECTS_CONTACTS: ['projects', 'contacts'],
  PROJECTS_CONTRIBUTORS: ['projects', 'contributors'],
  PROJECTS_DETAILS: ['projects', 'detail'],
  PROJECTS_SHORTLIST: ['projects', 'shortlist'],
  PROJECTS_STATISTICS: ['projects', 'statistics'],
  PROJECTS_PUBLIC: ['projects', 'public'],

  // Project Contacts
  PROJECT_CONTACTS_DETAILS: ['projects-contacts'],
  PROJECT_CONTACTS_SEARCH_TOKEN: ['projects-contacts', 'search-token'],

  // Search
  SEARCH_CONTRIBUTORS: ['search', 'contributors'],

  // Settings
  SETTINGS_EMAIL_NOTIFICATION_PREFERENCES: ['settings', 'email-notification-preferences'],

  // Subscriptions
  SUBSCRIPTIONS: ['subscriptions'],
  SUBSCRIPTIONS_CANCEL: ['subscriptions', 'cancel'],
  SUBSCRIPTIONS_SET_SESSION_ID: ['subscriptions', 'set_session_id'],
  SUBSCRIPTIONS_START: ['subscriptions', 'start'],

  // User
  USERS_ME_USER: ['users', 'me'],
  USERS_ME_PROFILE_PHOTO: ['users', 'me', 'profile_photo'],
  USERS_ME_PROFILE_PHOTO_AVATAR: ['users', 'me', 'profile_photo', 'avatar'],
  USERS_ME_USER_DATA: ['users', 'me', 'user_data']
};
