import { createMutation, createQuery, useQueryClient } from '@tanstack/svelte-query';
import type { CreateMutationOptions } from '@tanstack/svelte-query';
import { ApiError } from '$lib/api/errors';
import { QUERY_KEYS } from '$lib/queries/keys';
import * as api from '$lib/api';

export const subscriptionTypes = ['monthly', 'yearly'] as const;

export type SubscriptionType = (typeof subscriptionTypes)[number];
type StartSubscriptionProps = {
  subscriptionType: SubscriptionType;
  returnRoute?: string;
};

export const startSubscriptionMutation = () => {
  return createMutation<{ url: string }, ApiError, StartSubscriptionProps>({
    mutationKey: QUERY_KEYS.SUBSCRIPTIONS_START,
    mutationFn: async ({ subscriptionType, returnRoute }: StartSubscriptionProps) =>
      api.post<{ url: string }>(`/subscriptions`, {
        type: subscriptionType,
        return_route: returnRoute
      }),
    onSuccess: async ({ url }) => {
      window.location.replace(url);
    }
  });
};

type SubscriptionStatus =
  | 'free'
  | 'active'
  | 'expired'
  | 'expired_and_cancelled'
  | 'pending'
  | 'cancelled';
type Subscription = { status: SubscriptionStatus; expiry_date: string };

export const pollSubscriptionQuery = (enabled: boolean) =>
  createQuery<Subscription, ApiError>({
    queryKey: QUERY_KEYS.SUBSCRIPTIONS,
    queryFn: async () => api.get<Subscription>('/subscriptions'),
    refetchInterval: (query) => (query.state.data?.status === 'pending' ? 5000 : false),
    enabled
  });

export const cancelSubscriptionMutation = (
  opts: CreateMutationOptions<void, Error | ApiError> = {}
) => {
  const queryClient = useQueryClient();
  return createMutation<void, Error | ApiError>({
    mutationKey: QUERY_KEYS.SUBSCRIPTIONS_CANCEL,
    mutationFn: async () => api.del<void>(`/subscriptions`),
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.SUBSCRIPTIONS });
    },
    ...opts
  });
};

export const setSubscriptionSessionIdMutation = (
  opts: CreateMutationOptions<void, Error | ApiError, string> = {}
) => {
  const queryClient = useQueryClient();
  return createMutation<void, Error | ApiError, string>({
    mutationKey: QUERY_KEYS.SUBSCRIPTIONS_SET_SESSION_ID,
    mutationFn: async (sessionId: string) =>
      api.put<void>(`/subscriptions/session-id`, { session_id: sessionId }),
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.SUBSCRIPTIONS });
    },
    ...opts
  });
};
