<script lang="ts">
  import { formatMessageContent, toReadableDateTime } from '$lib/utils';
  import LoadingButton from '$lib/components/LoadingButton.svelte';
  import FieldBlock from '$lib/components/FieldBlock.svelte';
  import {
    getUserMessagesQuery,
    markMessagesAsReadMutation,
    type Message
  } from '$lib/queries/messaging';
  import * as auth from '$lib/auth';
  import { getMyUserData } from '$lib/queries/users';
  import { type CreateBaseMutationResult } from '@tanstack/svelte-query';
  import { type Readable } from 'svelte/store';
  import { ApiError } from '$lib/api/errors';
  import Badge from '@smui-extra/badge';
  import StretchyTextArea from '$lib/components/StretchyTextArea.svelte';
  import { goto } from '$app/navigation';

  const myDataQuery = getMyUserData();
  let message: string = '';

  export let topPadding = false;
  export let userRef: string | undefined;
  export let userName: string | undefined;
  export let loadMessageHistory: boolean = false;
  export let mutation: Readable<CreateBaseMutationResult<void, Error | ApiError, string>>;

  let lastLoadedUserRef: string | undefined;

  let messageContainerElement: HTMLDivElement;

  $: messagesQuery = getUserMessagesQuery(auth.isUnverifiedUser(userRef) ? undefined : userRef);
  $: markAsReadMutation = markMessagesAsReadMutation();

  $: if (messageContainerElement && $messagesQuery.isSuccess && userRef !== lastLoadedUserRef) {
    lastLoadedUserRef = userRef;
    setTimeout(() => {
      document.getElementById('message-input')?.focus();
    }, 350);
  }

  let unreadMessageToBeCleared: Message | undefined;
  let markAsReadTimeoutId: any | undefined = undefined;
  let allMessages: Message[] = [];
  $: if ($messagesQuery.data?.pages) {
    allMessages = $messagesQuery.data?.pages.reduce((acc, curr) => {
      if (curr) {
        acc.push(...curr.items);
      }
      return acc;
    }, [] as Message[]);
  }
  $: unreadMessage = allMessages.find(
    (item) => item.sender_ref === userRef && !item.read_by_recipient
  );
  $: if (unreadMessage && unreadMessage != unreadMessageToBeCleared) {
    unreadMessageToBeCleared = unreadMessage;
    clearTimeout(markAsReadTimeoutId);
    markAsReadTimeoutId = setTimeout(() => {
      if (unreadMessageToBeCleared) {
        $markAsReadMutation.mutate(unreadMessageToBeCleared.ref);
      }
    }, 3500);
  }

  const nextPageMessages = () => {
    clearTimeout(markAsReadTimeoutId);
    $messagesQuery.fetchNextPage();
    if (!loadMessageHistory) {
      goto(`/inbox?userRef=${userRef}`);
    }
  };

  const sendMessage = () => {
    if (!message) {
      return;
    }
    $mutation.mutate(message, {
      onSuccess: () => {
        message = '';
        setTimeout(() => {
          document.getElementById('message-input')?.focus();
        }, 10);
      }
    });
  };
  const metaEnterKeydown = (e: KeyboardEvent) => {
    if ((e.metaKey || e.ctrlKey) && e.key.toLowerCase() === 'enter') {
      sendMessage();
    }
  };
  const refToName = (ref: string) => {
    if (ref === $myDataQuery.data?.user__ref) {
      return [$myDataQuery.data?.first_name, $myDataQuery.data?.last_name].join(' ');
    }
    return userName;
  };
</script>

<FieldBlock loading={$messagesQuery.isLoading} {topPadding}>
  {#if auth.isUnverifiedUser(userRef)}
    <p>Please verify your email address before you can use this feature.</p>
  {/if}
  <nav aria-label="Page navigation" class="d-flex justify-content-center align-items-center mb-2">
    <LoadingButton
      loading={$messagesQuery.isLoading}
      title="Load more messages"
      on:click={nextPageMessages}
      disabled={!$messagesQuery.hasNextPage}
    >
      {#if $messagesQuery.hasNextPage}Load more{:else}Start of message history{/if}
    </LoadingButton>
  </nav>
  <div class="message-container" bind:this={messageContainerElement}>
    {#each allMessages.toReversed() || [] as item (item.ref)}
      <div class="message">
        <p class="title">
          {#if item.sender_ref !== $myDataQuery.data?.user__ref}
            <a class="name" href="/profiles/{item.sender_ref}"
              ><strong>{refToName(item.sender_ref)}</strong></a
            >
          {:else}
            <strong>{refToName(item.sender_ref)}</strong>
          {/if}
          <span class="created-at">• {toReadableDateTime(item.created_at)}</span>
        </p>
        <p class="content">
          <!-- eslint-disable-next-line svelte/no-at-html-tags -->
          {@html formatMessageContent(item.content)}
        </p>
        {#if userRef === item.sender_ref && !item.read_by_recipient}
          <Badge
            class="unread-badge-identifier"
            style="background-color:rgb(121, 40, 202);left:-12px;bottom:32px;"
            position="inset"
            align="middle-start"
            aria-label="unread notification count"
          />
        {/if}
      </div>
    {/each}
  </div>
  {#if $messagesQuery.isSuccess}
    <form on:submit={sendMessage}>
      <div
        style="display:flex; flex-direction: column; justify-content: flex-end;align-items: flex-end;margin-bottom: 2em"
      >
        <StretchyTextArea
          keydown={metaEnterKeydown}
          label="Write a message"
          id="message-input"
          bind:value={message}
          disabled={$mutation.isPending}
          on:submit={sendMessage}
        />
        <div class="d-flex justify-content-between align-items-center w-100">
          <span class="ps-2 small d-none d-md-inline-block" style="font-size: 80%"
            >Cmd/Ctrl + Enter to send the message</span
          >
          <LoadingButton loading={$mutation.isPending} class="justify-end">Send</LoadingButton>
        </div>
      </div>
    </form>
  {/if}
</FieldBlock>

<style lang="scss">
  .created-at {
    margin-top: 0;
    font-size: 0.8rem;
    color: #666;
  }

  .message-container {
    overflow-wrap: break-word;
  }

  .message {
    position: relative;

    .name {
      //text-decoration: none;
      color: rgb(121, 40, 202);
    }

    .title {
      margin-bottom: 0;
    }

    .content {
      margin-top: 0;
      white-space: pre-wrap;
    }
  }
</style>
