import type { TokenPaginationParams, TokenPaginationResponse } from '$lib/types';
import {
  createMutation,
  createQuery,
  createInfiniteQuery,
  useQueryClient
} from '@tanstack/svelte-query';
import { QUERY_KEYS } from '$lib/queries/keys';
import { toQueryString } from '$lib/utils';
import * as api from '$lib/api';
import { ApiError } from '$lib/api/errors';
import { showErrorToast } from '$lib/components/Toaster/stores';
import { oneMinuteInMs } from '$lib/constants';

type Conversation = {
  user__ref: string;
  info: {
    name?: string;
    avatar_url?: string;
  };
  last_message_date: string;
  content: string;
  has_unread_messages: boolean;
};

export const getConversationsQuery = (data: TokenPaginationParams) =>
  createQuery({
    queryKey: [...QUERY_KEYS.INBOX_CONVERSATIONS, data],
    queryFn: async () => {
      const qs = toQueryString(data);
      return api.get<TokenPaginationResponse<Conversation>>(`/messages/conversations?${qs}`);
    },
    gcTime: oneMinuteInMs
  });

export type Message = {
  sender_ref: string;
  recipient_ref: string;
  content: string;
  ref: string;
  created_at: string;
  read_by_recipient?: boolean;
};

export const getUserMessagesQuery = (userRef?: string) =>
  createInfiniteQuery<TokenPaginationResponse<Message> | null>({
    queryKey: [...QUERY_KEYS.INBOX_USER_MESSAGES, userRef],
    initialPageParam: null,
    queryFn: async ({ pageParam }) => {
      if (!userRef) {
        return null;
      }
      const qs = toQueryString({ last_ref: pageParam as string | null });
      return api.get<TokenPaginationResponse<Message>>(`/messages/users/${userRef}/messages?${qs}`);
    },
    enabled: !!userRef,
    gcTime: oneMinuteInMs,
    staleTime: oneMinuteInMs,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.has_more ? lastPage.items[lastPage.items.length - 1].ref : null;
    }
  });

export const sendMessageMutation = (recipientRef?: string) => {
  const queryClient = useQueryClient();
  return createMutation<void, Error | ApiError, string>({
    mutationKey: QUERY_KEYS.INBOX_SEND_MESSAGE,
    mutationFn: async (content) => {
      if (!recipientRef) {
        throw new Error('Recipient ref is required');
      }
      return api.post<void>(`/messages/users/${recipientRef}/messages`, { content });
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: [...QUERY_KEYS.INBOX_USER_MESSAGES, recipientRef]
      });
    },
    onError: async (error) => {
      showErrorToast(error);
    }
  });
};

export const getUnreadMessagesCountQuery = (enabled: boolean) =>
  createQuery({
    queryKey: QUERY_KEYS.INBOX_UNREAD_MESSAGE_COUNT,
    queryFn: async () => {
      return api.get<number>(`/messages/unread/count`);
    },
    gcTime: oneMinuteInMs,
    staleTime: oneMinuteInMs,
    refetchInterval: oneMinuteInMs,
    enabled
  });

export const markMessagesAsReadMutation = () => {
  const queryClient = useQueryClient();
  return createMutation({
    mutationFn: async (ref: string, clearMessages: boolean = false) =>
      api.put(`/messages/${ref}/read`, {}),
    onSettled: async () => {
      // We clear the listing and the count queries,
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.INBOX_CONVERSATIONS });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.INBOX_UNREAD_MESSAGE_COUNT });
    }
  });
};

export const sendBulkProjectMessageMutation = (projectRef: string) => {
  const queryClient = useQueryClient();
  return createMutation<
    { broadcast_count: number },
    Error | ApiError,
    { content: string; statuses: string[] }
  >({
    mutationKey: QUERY_KEYS.INBOX_SEND_BULK_MESSAGE,
    mutationFn: async ({ content, statuses }) =>
      api.post<{ broadcast_count: number }>(
        `/engagements/projects/${projectRef}/contributors/broadcast`,
        {
          content,
          statuses
        }
      ),
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.INBOX_CONVERSATIONS
      });
      await queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.INBOX_USER_MESSAGES
      });
    },
    onError: async (error) => {
      showErrorToast(error);
    }
  });
};
