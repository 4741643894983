export const PROJECT_CONTACT_STATUSES = {
  shortlisted: 'Shortlisted',
  invited: 'Invited',
  retracted: 'Invitation Retracted',
  accepted: 'Contributor Accepted',
  contributor_declined: 'Declined by Contributor',
  media_declined: 'Declined by Media',
  submitted: 'Submitted',
  approved: 'Approved'
};
export type ProjectContactStatus = keyof typeof PROJECT_CONTACT_STATUSES;
export const PROJECT_CONTACT_STATUSES_LIST = Object.entries(PROJECT_CONTACT_STATUSES)
  .filter(([key, value]) => key !== 'removed')
  .map(([key, value]) => ({
    id: key,
    label: value
  }));

export const SUPPORT_EMAIL = 'help@antiquoted.com';

export const socialOptions: Record<string, string> = {
  '0-750': 'Some (0-750 people)',
  '750-3000': 'A few (750-3000 people)',
  '3000-10000': 'Quite a few (3000 - 10k)',
  '10000-100000': 'Lots (10,001 - 100k)',
  '100000+': 'Tons (100,001+)'
};
export type SocialOptions = keyof typeof socialOptions;
export const SOCIAL_FORM_OPTIONS = Object.entries(socialOptions).map(([key, value]) => ({
  value: key,
  label: value
}));

export const PROJECT_STATUSES = {
  open: 'Open',
  collecting_responses: 'Collecting responses',
  completed: 'Completed',
  on_hold: 'On hold'
};
export type ProjectStatus = keyof typeof PROJECT_STATUSES;
export const PROJECT_STATUSES_LIST = Object.entries(PROJECT_STATUSES).map(([key, value]) => ({
  id: key,
  label: value
}));

const hostEnvMap: Record<string, string> = {
  'app.antiquoted.com': 'prod',
  'dev.antiquoted.com': 'dev',
  localhost: 'local'
};

export const getEnvName = () => hostEnvMap[window.location.hostname];

export const ALGOLIA_APPLICATION_ID = 'QXY2RGU0Q7';

export const oneHourInMilliseconds = 60 * 60 * 1000;
export const thirtyMinutesInMs = 30 * 60 * 1000;
export const tenMinutesInMs = 10 * 60 * 1000;
export const oneMinuteInMs = 60 * 1000;

export const DELETED_PLACEHOLDER = '[DELETED]';

export type NOTIFICATION_TYPE =
  | 'contribution_deadline_approaching'
  | 'contribution_deadline_soonish'
  | 'contributor_project_invited'
  | 'contributor_project_retracted'
  | 'contributor_project_accepted'
  | 'contributor_project_contributor_declined'
  | 'contributor_project_media_declined'
  | 'contributor_project_submitted'
  | 'contributor_project_approved'
  | 'project_deadline_approaching';

export enum UserType {
  both = 'both',
  contributor = 'contributor',
  media_person = 'media_person'
}
