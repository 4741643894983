import { ProjectContactStatus } from '$lib/constants';

export const statusDescriptionTooltips: Record<ProjectContactStatus, string> = {
  shortlisted:
    "Only you can see shortlisted users, for users to get notified about your project you'll need to invite them.",
  invited:
    "The contributor has been notified about this project, if they don't respond you can retract the invite form the project screen.",
  retracted:
    "You retracted your invitation to this contributor so you won't be able to re-invite them.",
  accepted: 'The contributor accepted your invitation. Reach out to them via the project screen.',
  contributor_declined: 'The contributor declined your invitation.',
  media_declined: "You declined the contributor's submission.",
  submitted:
    'The contributor has submitted their contribution. Approve it from the project screen.',
  approved: 'You approved this contributors submission.'
};
