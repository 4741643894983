<script lang="ts">
  import MainBorder from '$lib/components/MainBorder.svelte';
  import FieldBlock from '$lib/components/FieldBlock.svelte';
  import UserField from '$lib/components/UserTextField.svelte';
  import { Label } from '@smui/button';
  import Select, { Option } from '@smui/select';
  import DateField from '$lib/components/DateField.svelte';
  import StretchyTextArea from '$lib/components/StretchyTextArea.svelte';
  import { createProjectQuery } from '$lib/queries/projects';
  import { goto, onNavigate } from '$app/navigation';
  import { showErrorToast } from '$lib/components/Toaster/stores';
  import { ApiError } from '$lib/api/errors';
  import type { CreateProjectPojo } from '$lib/types';
  import LoadingButton from '$lib/components/LoadingButton.svelte';
  import { putProjectContributor } from '$lib/queries/contributors';
  import { page } from '$app/stores';
  import { onMount } from 'svelte';
  import { getProjectFieldInfo } from '$lib/project-utils';
  import { capitalise } from '$lib/utils.js';
  import * as auth from '$lib/auth';
  import PageTitle from '$lib/components/PageTitle.svelte';

  // This is here to fix some weird-ass behaviour when navigating from the profile page.
  let windowY = 0;
  onNavigate(async () => {
    windowY = 0;
  });

  const projectTypes = ['event', 'article', 'podcast'];
  let fields: CreateProjectPojo = {
    name: '',
    deadline: '',
    description: '',
    type: '',
    outlet: '',
    outlet_link: '',
    outlet_viewership: null,
    meeting_link: '',
    visibility: 'private'
  };
  let errorStates: Record<string, boolean> = {};

  const mutation = createProjectQuery();
  const putProjectContributorMutation = putProjectContributor();
  let shortlistRef: string | null;
  let inviteRef: string | null;
  onMount(() => {
    shortlistRef = $page.url.searchParams.get('shortlistContributor');
    inviteRef = $page.url.searchParams.get('inviteContributor');
  });

  const submit = async () => {
    if (!fields.type) {
      showErrorToast(new Error('Please select a type.'));
      return;
    }
    errorStates = {};
    $mutation.mutate(
      {
        ...fields,
        meeting_link: fields.meeting_link ? fields.meeting_link : undefined,
        outlet_link: fields.outlet_link ? fields.outlet_link : undefined,
        deadline: fields.deadline ? fields.deadline : undefined,
        description: fields.description ? fields.description : undefined
      } as CreateProjectPojo,
      {
        onSuccess: (data) => {
          if (shortlistRef) {
            $putProjectContributorMutation.mutate(
              { ref: shortlistRef, projectRefOverride: data.ref },
              {
                onSuccess: () => {
                  goto(`/projects/${data.ref}`);
                }
              }
            );
          } else if (inviteRef) {
            $putProjectContributorMutation.mutate(
              { ref: inviteRef, projectRefOverride: data.ref, status: 'invited' },
              {
                onSuccess: () => {
                  goto(`/projects/${data.ref}`);
                }
              }
            );
          } else {
            goto(`/projects/${data.ref}/shortlist?first_created=true`);
          }
        },
        onError: (e) => {
          if (e instanceof ApiError) {
            showErrorToast(e);
            const theId = e.errors?.[0].loc[2];
            if (theId) {
              errorStates[theId] = true;
            }
          }
        }
      }
    );
  };
  $: loading = $mutation.isPending || $mutation.isSuccess;
  $: projectFieldInfo = getProjectFieldInfo(fields.type);
</script>

<svelte:window bind:scrollY={windowY} />

<PageTitle name="Create a project" />

<form on:submit={submit}>
  <MainBorder backButton="/projects" backButtonTitle="Back to projects">
    <FieldBlock {loading}>
      <h3 slot="title">New Project</h3>
      <p>
        Projects are only visible to people you invite to contribute to them. You can edit/update
        the below information after the project has been created.
      </p>
      <div class="d-flex mb-2 justify-content-between gap-4">
        <Select
          bind:value={fields.type}
          label="Project Type"
          id="type"
          invalid={!!errorStates.type}
          class="flex-fill"
          required
          style="width: 100%;z-index:10"
        >
          {#each projectTypes as option}
            <Option value={option}>{capitalise(option)}</Option>
          {/each}
        </Select>
        <Select
          class="flex-fill"
          bind:value={fields.visibility}
          label="Visibility"
          id="visibility"
          invalid={!!errorStates.visiblity}
          required
        >
          <Option value="public">Public</Option>
          <Option value="private">Private</Option>
        </Select>
      </div>
      {#if fields.type}
        <UserField
          id="name"
          label="Project Headline"
          bind:value={fields.name}
          valueInvalid={!!errorStates.name}
          updateInvalid={false}
          helperText="Projects must have a name."
          type="text"
          required
        />
        <UserField
          id="outlet"
          label={projectFieldInfo.outletLabel}
          bind:value={fields.outlet}
          valueInvalid={!!errorStates.outlet}
          helperText="Name of the website, newspaper, podcast, or event where the contributor will be featured."
          type="text"
          required
        />
        <UserField
          id="outlet_link"
          label={projectFieldInfo.outletLinkLabel}
          bind:value={fields.outlet_link}
          valueInvalid={!!errorStates.outlet_link}
          type="url"
        />
        <UserField
          id="outlet_viewership"
          label={projectFieldInfo.outletViewershipLabel ?? ''}
          bind:value={fields.outlet_viewership}
          valueInvalid={!!errorStates.outlet_viewership}
          type="number"
          min={0}
          step={1}
        />
        <UserField
          id="meeting_link"
          label={projectFieldInfo.meetingLinkLabel}
          bind:value={fields.meeting_link}
          valueInvalid={!!errorStates.meeting_link}
          type="url"
        />
        <p class="field-heading" style="margin: 0 0 1.5em;color:rgba(0, 0, 0, 0.6)">
          {projectFieldInfo.meetingLinkSubtitle}
        </p>
        <DateField
          id="deadline"
          label={projectFieldInfo.deadlineLabel}
          bind:value={fields.deadline}
          helperText="Please set a valid date for the deadline."
          disabledMessage="This is an ongoing project."
        />
        <p class="field-heading" style="margin: 0 0 1.5em;color:rgba(0, 0, 0, 0.6)">
          Note: Contributors won't be able to respond to your project once it passes its deadline.
        </p>
        <p class="field-heading" style="margin-bottom: 0.5em">
          {projectFieldInfo.descriptionTitle} <br />
          <span style="color:rgba(0, 0, 0, 0.6);font-size: .9em">
            {projectFieldInfo.descriptionSubTitle}
          </span>
        </p>
        <StretchyTextArea
          label="Project description"
          id="submission"
          bind:value={fields.description}
          invalid={!!errorStates.description}
          showCharacterCount
          required
        />
      {/if}
      <div class="text-center mb-5 mt-5">
        <LoadingButton loading={$mutation.isPending} type="submit" variant="outlined">
          <Label style="z-index: 1">Create Project</Label>
        </LoadingButton>
      </div>
    </FieldBlock>
  </MainBorder>
</form>
