<script lang="ts">
  export let style: string | undefined = undefined;
</script>

<div {style} {...$$restProps} />

<style lang="scss">
  div {
    display: block;
    height: 2em;
    line-height: 3em;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
    margin: 1em 0;
  }

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
</style>
