import type { TokenPaginationParams, TokenPaginationResponse } from '$lib/types';
import { createMutation, createQuery, useQueryClient } from '@tanstack/svelte-query';
import { QUERY_KEYS } from '$lib/queries/keys';
import { toQueryString } from '$lib/utils';
import * as api from '$lib/api';
import { NOTIFICATION_TYPE, oneMinuteInMs } from '$lib/constants';

export type Notification = {
  ref: string;
  content: string;
  created_at: string;
  is_read: Boolean;
  target?: string;
  meta?: Record<string, any>;
  type: NOTIFICATION_TYPE;
};

export const getMyNotificationsQuery = (data: TokenPaginationParams, enabled: boolean = true) =>
  createQuery({
    queryKey: [...QUERY_KEYS.NOTIFICATIONS, data],
    queryFn: async () => {
      const qs = toQueryString(data);
      return api.get<TokenPaginationResponse<Notification>>(`/notifications?${qs}`);
    },
    gcTime: oneMinuteInMs,
    staleTime: 1000,
    enabled
  });

export const getUnreadNotificationCountQuery = (enabled: boolean) =>
  createQuery({
    queryKey: QUERY_KEYS.NOTIFICATIONS_COUNT,
    queryFn: async () => {
      return api.get<number>(`/notifications/unread/count`);
    },
    gcTime: oneMinuteInMs,
    staleTime: oneMinuteInMs,
    refetchInterval: oneMinuteInMs,
    enabled
  });

export const markNotificationAsReadMutation = () => {
  const queryClient = useQueryClient();
  return createMutation({
    mutationKey: QUERY_KEYS.NOTIFICATIONS_DETAIL,
    mutationFn: async (ref: string) => api.put(`/notifications/${ref}/read`, {}),
    onSettled: async () => {
      // We clear the detail and the count queries,
      // but we don't wanna clear the listing so it stays "read" visibly for a while
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.NOTIFICATIONS_DETAIL });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.NOTIFICATIONS_COUNT });
    }
  });
};
