<script lang="ts">
  import { toReadableDate } from '$lib/utils.js';
  import Dialog, { Actions, Content, Title } from '@smui/dialog';
  import { Label } from '@smui/button';
  import LoadingButton from '$lib/components/LoadingButton.svelte';
  import { getEnvName } from '$lib/constants';
  import { cancelSubscriptionMutation, pollSubscriptionQuery } from '$lib/queries/subscription';

  export let open: boolean;

  $: enabled = getEnvName() !== 'prod';

  const subscriptionQuery = pollSubscriptionQuery(enabled);
  const cancelSubscription = cancelSubscriptionMutation({
    onSuccess: () => {
      open = false;
    }
  });
</script>

<Dialog
  bind:open
  aria-labelledby="Cancel subscription modal"
  aria-describedby="A Modal used to show the paywall"
  surface$style="width: 1050px; max-width: calc(100vw - 32px);max-width: calc(100vh - 50px);"
>
  <Title>Cancel your subscription</Title>
  <Content>
    <p>
      Are you sure you want to cancel? You'll still be able to use premium features until your
      subscription expires (on {toReadableDate($subscriptionQuery.data?.expiry_date)}) but you'll
      lose access to these features after that date.
    </p>
    <LoadingButton
      on:click={() => $cancelSubscription.mutate()}
      loading={$cancelSubscription.isPending}
    >
      <Label>Cancel subscription</Label>
    </LoadingButton>
  </Content>
  <Actions>
    <LoadingButton loading={$cancelSubscription.isPending} action="close">
      <Label>Back</Label>
    </LoadingButton>
  </Actions>
</Dialog>
