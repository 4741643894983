<script lang="ts">
  import Dialog, { Content, Title } from '@smui/dialog';
  import Button, { Label } from '@smui/button';
  import LoadingButton from '$lib/components/LoadingButton.svelte';
  import { hidePaywallModal, paywallModalStore } from '$lib/components/WarningModal/stores';
  import { startSubscriptionMutation, type SubscriptionType } from '$lib/queries/subscription';
  import { page } from '$app/stores';
  import { nextMonthName } from '$lib/utils';
  import { showErrorToast } from '$lib/components/Toaster/stores';
  import { goto } from '$app/navigation';

  const startSubscription = startSubscriptionMutation();
  let selected: SubscriptionType | null = null;
  let returnRoute: string | undefined = undefined;
  $: if ($page.url?.pathname !== '/settings') {
    returnRoute = $page.url?.pathname;
  } else {
    const subscriptionType = $page.url.searchParams.get(
      'trigger_subscription'
    ) as SubscriptionType | null;
    if (subscriptionType && !$startSubscription.isPending) {
      $page.url.searchParams.delete('trigger_subscription');
      goto('?' + $page.url.searchParams.toString());
      $startSubscription.mutate({ subscriptionType, returnRoute: undefined });
    }
  }

  const mutate = (subscriptionType: 'monthly' | 'yearly') => {
    selected = subscriptionType;
    $startSubscription.mutate({ subscriptionType, returnRoute });
  };
</script>

<Dialog
  bind:open={$paywallModalStore}
  aria-labelledby="Paywall modal"
  aria-describedby="A Modal used to show the paywall"
  surface$style="width: 800px;max-width: calc(90vw - 32px);"
>
  <Title class="text-center">Invite unlimited new & diverse contributors</Title>
  <Content>
    <div class="row mb-1 pb-1 pt-1">
      <div class="col-md-6 text-center mt-3 first-block">
        <p class="mb-3">3 invites per month.</p>
        <Button
          disabled={$startSubscription.isPending}
          variant="outlined"
          on:click={hidePaywallModal}
          >Stay on free
        </Button>
        <p class="mt-3">3/3 invites used.</p>
        <small class="mb-3">Your invite allowance renews on the 1st of {nextMonthName()}</small>
      </div>
      <div class="col-md-6 text-center mt-3">
        <p>$14 per month</p>
        <div class="d-inline-block text-start">
          <ul>
            <li>Unlimited invites*</li>
            <li>Cancel any time</li>
          </ul>
        </div>
        <div class="mt-1 d-flex justify-content-center align-items-start gap-2">
          <LoadingButton
            disabled={selected === 'yearly'}
            on:click={() => mutate('monthly')}
            loading={selected === 'monthly' && $startSubscription.isPending}
            variant="outlined"
          >
            <Label>$14 monthly</Label>
          </LoadingButton>
          <div>
            <LoadingButton
              disabled={selected === 'monthly'}
              on:click={() => mutate('yearly')}
              loading={selected === 'yearly' && $startSubscription.isPending}
              variant="raised"
            >
              <Label>$69 yearly</Label>
            </LoadingButton>
            <p><small><strong>Save $99!</strong></small></p>
          </div>
        </div>
        <!--        ToDo: Make this a link to website page info-->
        <a href="/settings"><small>*See all features & fair usage</small></a>
      </div>
    </div>
  </Content>
</Dialog>

<style lang="scss">
  .first-block {
    @media (min-width: 768px) {
      border-right: rgba(0, 0, 0, 0.2) 2px solid;
    }
    @media (max-width: 767px) {
      width: 80%;
      padding-bottom: 2em;
      margin-bottom: 1.5em;
      margin-left: auto;
      margin-right: auto;
      border-bottom: rgba(0, 0, 0, 0.3) 2px solid;
    }
  }

  ul li {
    color: rgba(0, 0, 0, 0.87);
  }
</style>
