<script lang="ts">
  import MainBorder from '$lib/components/MainBorder.svelte';
  import PageTitle from '$lib/components/PageTitle.svelte';
  import FieldBlock from '$lib/components/FieldBlock.svelte';
  import {
    getProjectQuery,
    getProjectSubmissionsQuery,
    markSubmissionsAsReadMutationQuery
  } from '$lib/queries/projects';
  import { Label } from '@smui/button';
  import { getPronounOptions } from '$lib/queries/common';
  import Badge from '@smui-extra/badge';
  import DeclineModal from '$lib/components/DeclineModal.svelte';
  import {
    changeContributorProjectStatus,
    sendProjectContributorMessage
  } from '$lib/queries/contributors';
  import { type ProjectSubmission } from '$lib/types';
  import Dialog, { Actions, Content, Title } from '@smui/dialog';
  import MessageThread from '$lib/components/MessageThread.svelte';
  import LoadingButton from '$lib/components/LoadingButton.svelte';
  import { DELETED_PLACEHOLDER } from '$lib/constants';
  import TooltipIconButton from '$lib/components/Projects/TooltipIconButton.svelte';
  import ChangeStatusTooltipIconButton from '$lib/components/Projects/ChangeStatusTooltipIconButton.svelte';
  import Card, { Content as CardContent, Actions as CardActions, ActionIcons } from '@smui/card';
  import Avatar from '$lib/components/Images/Avatar.svelte';

  export let data;

  let cardRefs: Record<string | undefined, Card> = {};
  let selectedContributorRef: string = '';

  let changeStatusLoading = false;
  let declineModalOpen = false;

  let chatModalOpen = false;
  let selectedChatSubmission: ProjectSubmission;

  $: sendContributorMessageMutation = sendProjectContributorMessage(selectedContributorRef);
  $: changeContributorProjectStatusMutation = changeContributorProjectStatus(
    data.params.ref,
    selectedContributorRef
  );
  const pronounOptions = getPronounOptions();
  const query = getProjectSubmissionsQuery(data.params.ref);
  const project = getProjectQuery(data.params.ref);
  const markAsReadMutation = markSubmissionsAsReadMutationQuery(data.params.ref);
  $: loading =
    $query.isLoading || $pronounOptions.isLoading || $project.isLoading || changeStatusLoading;

  let scrollRef: string = '';
  let firstOpen = false;
  $: if ($query.data && !firstOpen) {
    $markAsReadMutation.mutate($query.data.filter(({ is_read }) => !is_read).map(({ ref }) => ref));

    let contributorRef = new URLSearchParams(window.location.search).get('contributorRef');
    if (contributorRef) {
      const submissionRef = $query.data.find((i) => i.contributor__ref === contributorRef)?.ref;
      if (submissionRef) {
        scrollRef = submissionRef;
      }
    }

    firstOpen = true;
  }
  let scrolled = false;
  $: if ($query.data && !scrolled && cardRefs[scrollRef]) {
    setTimeout(() => {
      cardRefs[scrollRef].getElement().scrollIntoView({ block: 'start', behavior: 'smooth' });
    }, 150);

    scrolled = true;
  }

  const openChatModal = (submission: ProjectSubmission) => {
    selectedContributorRef = submission.contributor__ref;
    selectedChatSubmission = submission;
    chatModalOpen = true;
  };

  const closeChatModal = () => {
    chatModalOpen = false;
  };
  const declinedStatuses = new Set(['contributor_declined', 'media_declined']);

  const toPanelTitle = (submission: ProjectSubmission) => {
    if (!submission.contributor__ref) {
      return `Deleted`;
    } else if (declinedStatuses.has(submission.status)) {
      return `Declined`;
    } else if (submission.status === 'approved') {
      return `Approved`;
    } else {
      return `Pending`;
    }
  };
</script>

<PageTitle name="Project responses" />

<MainBorder backButton="/projects/{data.params.ref}" backButtonTitle="Back to project">
  <FieldBlock {loading}>
    <h3 class="mb-4">{$project.data?.name}</h3>
    {#each $query.data || [] as submission}
      <Card
        class="mb-3{scrollRef === submission.ref ? ' card-highlighted' : ''}"
        bind:this={cardRefs[submission.ref]}
      >
        <CardContent class="mdc-typography--body2">
          {#if !submission.is_read}
            <Badge
              color="primary"
              align="middle-start"
              aria-label="Unread content is available"
              style="min-height: 10px; min-width: 10px; padding: 0;"
            />
          {/if}
          <p>
            <strong>{toPanelTitle(submission)}</strong> submission from
            {submission.contributor__first_name ?? DELETED_PLACEHOLDER}
            {submission.contributor__last_name ?? DELETED_PLACEHOLDER}
          </p>
          {#if submission.submitted_at}
            <p class="note mb-0">
              Submitted on {submission.submitted_at &&
                new Date(submission.submitted_at).toLocaleDateString()}
            </p>
          {/if}
          {#if submission.status === 'approved'}
            <p class="note mb-0">You have approved this submission</p>
          {/if}

          {#if submission.submission && !declinedStatuses.has(submission.status)}
            <p class="mt-3" style="white-space: pre-wrap;">
              “{submission.submission}”
            </p>
          {/if}
          {#if submission.decline_reason && declinedStatuses.has(submission.status)}
            <p style="white-space: pre-wrap;">
              Decline reason: “{submission.decline_reason}”
            </p>
          {/if}
          <div class="d-flex gap-3 align-items-center mt-3">
            <a href="/profiles/{submission.contributor__user__ref}">
              <Avatar
                src={submission.contributor__avatar_url}
                small
                hasAvatar={!!submission.contributor__avatar_url}
              />
            </a>
            <div class="d-flex flex-column">
              <span>
                {#if submission.contributor__ref}
                  <a href="/profiles/{submission.contributor__user__ref}"
                    >{submission.contributor__first_name} {submission.contributor__last_name}</a
                  >
                {:else}
                  {DELETED_PLACEHOLDER} {DELETED_PLACEHOLDER}
                {/if}
                {#if submission.contributor__pronouns}
                  ({$pronounOptions.data?.[submission.contributor__pronouns]})
                {/if}
              </span>
              <span>
                {#if submission.contributor__job_title}
                  {submission.contributor__job_title}
                {/if}
                {#if submission.contributor__company_name}
                  at {submission.hide_company_name ? '*' : ''}{submission.contributor__company_name}
                {/if}
              </span>
            </div>
          </div>
          {#if submission.hide_company_name}
            <p class="note mt-3">
              *Important note: The contributor has asked that you don't show their company name in
              relation to this submission
            </p>
          {/if}
        </CardContent>
        {#if submission.contributor__ref}
          <CardActions>
            <ActionIcons>
              {#if submission.contributor__raw_avatar_url}
                <TooltipIconButton
                  action={() => {
                    window.open(submission.contributor__raw_avatar_url, '_blank');
                  }}
                  icon="image"
                  tooltip="Download this users profile photo."
                />
              {/if}
              {#if submission.status === 'submitted'}
                <ChangeStatusTooltipIconButton
                  status="approved"
                  projectRef={data.params.ref}
                  contributorRef={submission.contributor__ref}
                />
              {/if}
              {#if !declinedStatuses.has(submission.status)}
                <TooltipIconButton
                  icon="chat"
                  tooltip="Ask the contributor a question about the submission"
                  action={() => {
                    openChatModal(submission);
                  }}
                />
              {/if}
              {#if !declinedStatuses.has(submission.status)}
                <TooltipIconButton
                  icon="close"
                  tooltip="Decline submission"
                  action={() => {
                    declineModalOpen = true;
                  }}
                />
              {/if}
            </ActionIcons>
          </CardActions>
        {/if}
      </Card>
    {:else}
      <p>No responses yet.</p>
    {/each}
  </FieldBlock>
</MainBorder>

<Dialog
  bind:open={chatModalOpen}
  aria-labelledby="Ask a Question"
  aria-describedby="Ask a question about the request"
  surface$style="width: 650px; max-width: calc(100vw - 32px);"
>
  <Title>Message {selectedChatSubmission?.contributor__first_name}</Title>
  <Content>
    {#if chatModalOpen && $query.data}
      <MessageThread
        userRef={selectedChatSubmission.contributor__user__ref}
        userName="{selectedChatSubmission.contributor__first_name}  {selectedChatSubmission.contributor__last_name}"
        mutation={sendContributorMessageMutation}
      />
    {/if}
  </Content>
  <Actions>
    <LoadingButton
      loading={false}
      disabled={$sendContributorMessageMutation.isPending}
      on:click={closeChatModal}
    >
      <Label>Close</Label>
    </LoadingButton>
  </Actions>
</Dialog>

<DeclineModal
  bind:open={declineModalOpen}
  mutation={changeContributorProjectStatusMutation}
  status="media_declined"
  asMedia
/>

<style>
  :global(.card-highlighted) {
    border-color: rgb(121, 40, 202);
    border-width: 1px;
    border-style: solid;
  }

  .note {
    font-size: 0.8rem;
    color: #666;
  }
</style>
