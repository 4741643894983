<script lang="ts">
  import MainBorder from '$lib/components/MainBorder.svelte';
  import FieldBlock from '$lib/components/FieldBlock.svelte';
  import { getContributorQuery } from '$lib/queries/contributors';

  import { getProjectQuery } from '$lib/queries/projects';
  import Profile from '$lib/components/Profiles/Profile.svelte';
  import ErrorChunk from '$lib/components/ErrorChunk.svelte';
  import { SUPPORT_EMAIL } from '$lib/constants';

  export let data;
  $: projectRef = data.params.ref;
  $: contributorRef = data.params.contributorRef;
  $: project = getProjectQuery(projectRef);
  $: profile = getContributorQuery(contributorRef);

  $: contributionStatus = $project.data?.shortlist.find(
    (item) => item.ref === `contributor:${contributorRef}`
  )?.status;
  $: backLink = `/projects/${projectRef}/shortlist`;
</script>

{#if $project.isSuccess && $profile.isSuccess}
  <Profile userRef={$profile.data?.user__ref} {projectRef} {contributionStatus} {backLink} />
{:else}
  <MainBorder wide>
    <FieldBlock loading={$project.isLoading || $profile.isLoading}>
      {#if $profile.error?.status === 404 || $project.error?.status === 404}
        <h3>Profile not found</h3>
        <p>Sorry we couldn't find this profile.</p>
      {:else}
        <ErrorChunk />
      {/if}
    </FieldBlock>
  </MainBorder>
{/if}
