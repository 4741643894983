/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { goto } from '$app/navigation';
import { getCookie } from './cookies';

const localStorageSessionTokenKey = 'sessionToken';

export type LoginPayload = { refresh_token: string; token: string };

export const setSessionToken = (token: string) =>
  window.localStorage.setItem(localStorageSessionTokenKey, token);

export const getSessionToken = () => window.localStorage.getItem(localStorageSessionTokenKey);

export const getRefreshTokenExpiry = () => getCookie('aq-refresh-token-expiry', undefined);

const parseJWT = (token: string) => JSON.parse(window.atob(token.split('.')[1]));

export const isLoggedIn = () => {
  try {
    const sessionToken = parseJWT(getSessionToken()!);
    const refreshTokenExpiry = Number(getRefreshTokenExpiry()!);
    const currentTimestamp = new Date().getTime() / 1000;
    return sessionToken.exp > currentTimestamp || refreshTokenExpiry > currentTimestamp;
  } catch (e) {
    return false;
  }
};

export const isAdmin = () => {
  try {
    const sessionToken = parseJWT(getSessionToken()!);
    return isLoggedIn() && !!sessionToken.scope?.split(' ').includes('admin');
  } catch {
    return false;
  }
};

export const canSeeProjectManagement = () => {
  try {
    const sessionToken = parseJWT(getSessionToken()!);
    return isAdmin() || !!sessionToken.scope?.split(' ').includes('user');
  } catch {
    return false;
  }
};

// Note: unused argument is there to help trick svelte reactivity.
export const isUnverifiedUser = (_unused?: any) => {
  try {
    const sessionToken = parseJWT(getSessionToken()!);
    return sessionToken.scope?.split(' ').includes('unverified_user');
  } catch {
    return false;
  }
};

export const isPremiumUser = (_unused?: any) => {
  try {
    const sessionToken = parseJWT(getSessionToken()!);
    return sessionToken.scope?.split(' ').includes('premium');
  } catch {
    return false;
  }
};

// ToDo: nuke refresh cookie timeout here too as that's what api checking uses.
export const logout = () => {
  window.localStorage.removeItem(localStorageSessionTokenKey);
  window.location.pathname = '/';
};

export const logIn = async (data: LoginPayload, route = '/') => {
  setSessionToken(data.token);
  await goto(route);
  window.location.reload();
};
