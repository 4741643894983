import { createMutation, createQuery, useQueryClient } from '@tanstack/svelte-query';
import { QUERY_KEYS } from '$lib/queries/keys';
import { User, UserData } from '$lib/types';
import * as api from '$lib/api';
import { ApiError } from '$lib/api/errors';
import { showErrorToast } from '$lib/components/Toaster/stores';

const userUrl = '/users/self';
const userDataUrl = '/users/me';
const profilePhotoUrl = '/users/me/profile/photo';
const profilePhotoAvatarUrl = `${profilePhotoUrl}/avatar`;

export const getMyUserData = (ignore404: boolean = false, enabled: boolean = true) =>
  createQuery({
    enabled,
    queryKey: QUERY_KEYS.USERS_ME_USER_DATA,
    queryFn: async (): Promise<UserData | null> => {
      try {
        return await api.get<UserData>(userDataUrl);
      } catch (e: any) {
        if (e.status === 404 && ignore404) {
          return null;
        }
        throw e;
      }
    }
  });

export const getMyUser = (enabled: boolean = true) =>
  createQuery({
    enabled,
    queryKey: QUERY_KEYS.USERS_ME_USER,
    queryFn: async (): Promise<User | null> => {
      try {
        return await api.get<User>(userUrl);
      } catch (e: any) {
        if (e.status === 404) {
          return null;
        }
        throw e;
      }
    }
  });

type MinimumUserData = {
  first_name: string;
  last_name: string;
  job_title: string;
  company_name: string;
};
export const myUserDataMutation = () => {
  const queryClient = useQueryClient();
  return createMutation<void, Error | ApiError, MinimumUserData>({
    mutationFn: (data) => api.put(userDataUrl, data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: QUERY_KEYS.USERS_ME_USER_DATA }),
    mutationKey: QUERY_KEYS.USERS_ME_USER_DATA
  });
};

export const myUserTypeMutation = () => {
  const queryClient = useQueryClient();
  return createMutation<void, Error | ApiError, { user_type: 'media_person' }>({
    mutationFn: (data) => api.put(`${userDataUrl}/user-type`, data),
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({ queryKey: QUERY_KEYS.CONTRIBUTOR_MY_PROFILE }),
        queryClient.invalidateQueries({ queryKey: QUERY_KEYS.USERS_ME_USER_DATA })
      ]),
    mutationKey: QUERY_KEYS.USERS_ME_USER_DATA
  });
};

export const myProfilePhotoQuery = () =>
  createQuery({
    queryKey: QUERY_KEYS.USERS_ME_PROFILE_PHOTO_AVATAR,
    queryFn: async (): Promise<string | null> => {
      try {
        const { url } = await api.get<{ url: string }>(profilePhotoAvatarUrl);
        return url;
      } catch (e: any) {
        if (e.status === 404) {
          return null;
        }
        throw e;
      }
    }
  });

type ImagePayload = {
  url: string;
};

export const myProfilePhotoMutation = () => {
  const queryClient = useQueryClient();
  return createMutation<ImagePayload, ApiError, File | Blob>({
    mutationFn: (data: File | Blob) => api.upload<ImagePayload>(profilePhotoUrl, data),
    onSuccess: async ({ url }) => {
      queryClient.setQueryData(QUERY_KEYS.USERS_ME_PROFILE_PHOTO_AVATAR, url);
    },
    onError: (err) => {
      if (err.status === 413) {
        showErrorToast(new ApiError(413, 'File size is too big, please keep it below 10MB.'));
      } else {
        showErrorToast(err);
      }
    },
    mutationKey: QUERY_KEYS.USERS_ME_PROFILE_PHOTO_AVATAR
  });
};
