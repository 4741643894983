<script lang="ts">
  import { onClickAndKeydown } from '$lib/directives';

  import { goto } from '$app/navigation';
  import MainBorder from '$lib/components/MainBorder.svelte';
  import FieldBlock from '$lib/components/FieldBlock.svelte';
  import { Icon } from '@smui/icon-button';
  import type { ProjectList } from '$lib/types';
  import Button, { Label } from '@smui/button';
  import PageTitle from '$lib/components/PageTitle.svelte';
  import { PROJECT_STATUSES, PROJECT_STATUSES_LIST } from '$lib/constants';
  import List, { Graphic, Item, Text } from '@smui/list';
  import Dialog, { Actions, Content, Title } from '@smui/dialog';
  import Radio from '@smui/radio';
  import { getProjectsQuery, updateProjectQuery } from '$lib/queries/projects';
  import AQTable from '$lib/components/AQTable.svelte';
  import { toReadableDate } from '$lib/utils';
  import SortableTh from '$lib/components/SortableTh.svelte';
  import AddProjectButton from '$lib/components/AddProjectButton.svelte';
  import TooltipIconButton from '$lib/components/Projects/TooltipIconButton.svelte';
  import CustomTooltip from '$lib/components/CustomTooltip.svelte';

  let projectRef: string;
  let statusGroup: keyof typeof PROJECT_STATUSES;
  let statusOpen = false;

  const updateProjectMutation = updateProjectQuery();
  const updateStatus = () => {
    $updateProjectMutation.mutate({ ref: projectRef, data: { status: statusGroup } });
  };
  const openStatusDialog = (project: ProjectList) => {
    statusOpen = true;
    statusGroup = project.status;
    projectRef = project.ref;
  };

  let rowsPerPage = 10;
  let currentPage = 0;
  let sort = 'created_at';
  let order: 'asc' | 'desc' = 'desc';

  let query = getProjectsQuery({
    sort,
    sort_direction: order,
    limit: rowsPerPage,
    page: currentPage + 1
  });
  $: query = getProjectsQuery({
    sort,
    sort_direction: order,
    limit: rowsPerPage,
    page: currentPage + 1
  });
  $: projects = $query.data?.items ?? [];
  $: loading = $query.isLoading;
</script>

<PageTitle name="Projects" />

<MainBorder wide>
  <div slot="top">
    <h3 class="mt-3">Your Projects</h3>
    <p>
      This is your list of projects. You can add projects using the button below or simply click on
      one to view (and edit) the details.
    </p>
    <div class="d-flex align-items-center gap-2 mt-2 mb-4">
      <AddProjectButton variant="raised" />
      <a href="/projects/public">
        <Button variant="outlined">
          <Label>Publicly available projects</Label>
        </Button>
      </a>
    </div>
  </div>
  <FieldBlock {loading}>
    <AQTable
      bind:currentPage
      totalCount={$query.data?.total_count ?? 0}
      {rowsPerPage}
      paginationName="Projects"
    >
      <svelte:fragment slot="header">
        <SortableTh bind:direction={order} fieldName="name" bind:sort>Title</SortableTh>
        <SortableTh bind:direction={order} fieldName="deadline" bind:sort>Deadline</SortableTh>
        <th class="text-center">Responses</th>
        <SortableTh bind:direction={order} fieldName="status" bind:sort class="text-center"
          >Status
        </SortableTh>
      </svelte:fragment>
      <svelte:fragment slot="body">
        {#if projects.length === 0}
          <tr>
            <td colspan={4}>No projects found.</td>
          </tr>
        {/if}
        {#each projects as item (item.ref)}
          <tr>
            <td
              style="cursor:pointer;"
              use:onClickAndKeydown={() => {
                goto(`/projects/${item.ref}`);
              }}>{item.name}</td
            >
            <td
              style="cursor:pointer;"
              use:onClickAndKeydown={() => {
                goto(`/projects/${item.ref}`);
              }}>{toReadableDate(item.deadline)}</td
            >
            <td class="d-flex justify-content-center">
              <TooltipIconButton
                disabled={item.response_count === 0}
                icon="visibility"
                url="/projects/{item.ref}/responses?submissionRef=${item.ref}"
                tooltip="View responses"
              />
            </td>
            <td class="text-center">
              <CustomTooltip content="Click this to change this project's status">
                <Button on:click={() => openStatusDialog(item)}>
                  <Label>{PROJECT_STATUSES[item.status]}</Label>
                  <Icon class="material-icons" style="margin: 0;">arrow_drop_down</Icon>
                </Button>
              </CustomTooltip>
            </td>
          </tr>
        {/each}
      </svelte:fragment>
    </AQTable>
  </FieldBlock>
</MainBorder>

<Dialog
  bind:open={statusOpen}
  aria-labelledby="Update status"
  aria-describedby="Update status of a projects"
>
  <Title id="simple-title">Update Status</Title>
  <Content id="list-selection-content">
    <List radioList>
      {#each PROJECT_STATUSES_LIST as status (status.id)}
        {#if status.id === 'completed' && statusGroup === 'completed'}
          <p class="warning-text">
            Great work! When a project is ‘completed’, contributors won’t be able to submit any more
            responses to it.
          </p>
        {/if}
        <Item>
          <Graphic>
            <Radio bind:group={statusGroup} value={status.id} />
          </Graphic>
          <Text>{status.label}</Text>
        </Item>
      {/each}
    </List>
  </Content>
  <Actions>
    <Button
      on:click={() => {
        statusOpen = false;
      }}
    >
      <Label>Cancel</Label>
    </Button>
    <Button on:click={updateStatus}>
      <Label>Update</Label>
    </Button>
  </Actions>
</Dialog>

<style lang="scss">
  @use '@material/theme/color-palette';

  .warning-text {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    border-style: solid;
    border-color: color-palette.$orange-300;
    border-width: 1px;
    background-color: color-palette.$orange-100;
    padding: 0.5em 0 0.5em 1em;
    border-radius: 0.25em;
  }
</style>
