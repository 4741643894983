import { createMutation, createQuery, useQueryClient } from '@tanstack/svelte-query';
import { QUERY_KEYS } from '../queries/keys';
import * as api from '../api';
import {
  Contact,
  ContributorRecommendationResult,
  CreateProjectPojo,
  Project,
  ProjectList,
  ProjectSubmission,
  PublicProject,
  ShortlistEntry,
  StandardPaginationParams
} from '../types';
import { toQueryString } from '$lib/utils';
import { ProjectContactStatus } from '$lib/constants';
import { ApiError } from '$lib/api/errors';
import { goto } from '$app/navigation';

export const getProjectsQuery = (
  { limit, page, sort, sort_direction }: StandardPaginationParams,
  enabled: boolean = true
) =>
  createQuery({
    queryKey: [...QUERY_KEYS.PROJECTS_ALL, { limit, page, sort, sort_direction }],
    queryFn: async () => {
      const qs = toQueryString({
        limit: limit.toString(),
        page: page.toString(),
        sort,
        sort_direction
      });
      return api.get<{
        items: ProjectList[];
        total_count: number;
      }>(`/engagements/projects?${qs}`);
    },
    enabled
  });

export const getDashboardProjectsQuery = (enabled: boolean) =>
  getProjectsQuery(
    {
      limit: 5,
      page: 1,
      sort: 'created_at',
      sort_direction: 'desc'
    },
    enabled
  );

export const getPublicProjectsQuery = (
  { limit, page, sort, sort_direction }: StandardPaginationParams,
  enabled: boolean = true
) =>
  createQuery({
    queryKey: [...QUERY_KEYS.PROJECTS_PUBLIC, 'search', { limit, page, sort, sort_direction }],
    queryFn: async () => {
      const qs = toQueryString({
        limit: limit.toString(),
        page: page.toString(),
        sort,
        sort_direction
      });
      return api.get<{
        items: PublicProject[];
        total_count: number;
      }>(`/engagements/projects/public?${qs}`);
    },
    enabled
  });

export const getPublicProjectQuery = (ref: string) =>
  createQuery({
    queryKey: [...QUERY_KEYS.PROJECTS_PUBLIC, ref],
    queryFn: async () => {
      return api.get<PublicProject>(`/engagements/projects/public/${ref}`);
    }
  });

export const acceptPublicProjectMutation = (projectRef: string) => {
  const queryClient = useQueryClient();
  return createMutation<{ project_contributor_ref: string }, Error | ApiError>({
    mutationKey: QUERY_KEYS.INVITATION_DETAILS,
    mutationFn: async () => api.post(`/engagements/projects/public/${projectRef}/acceptance`, {}),
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.PROJECTS_PUBLIC
      });
    },
    onSuccess: async ({ project_contributor_ref }) => {
      return goto(`/requests/${project_contributor_ref}`);
    }
  });
};

export const getAvailableProjectsQuery = () =>
  createQuery({
    queryKey: QUERY_KEYS.PROJECTS_AVAILABLE,
    queryFn: async () => {
      return api.get<ProjectList[]>(`/engagements/projects/available`);
    }
  });

export const getProjectQuery = (ref?: string) =>
  createQuery<Project, ApiError>({
    queryKey: [...QUERY_KEYS.PROJECTS_DETAILS, ref],
    queryFn: async () => api.get<Project>(`/engagements/projects/${ref}`),
    enabled: !!ref
  });

export const getProjectSubmissionsQuery = (ref: string) =>
  createQuery({
    queryKey: [...QUERY_KEYS.PROJECTS_DETAILS, ref, 'submissions'],
    queryFn: async () => api.get<ProjectSubmission[]>(`/engagements/projects/${ref}/submissions`),
    staleTime: 5_000
  });

type ContactResponseData = {
  items: Contact[];
  total_count: number;
};

type ProjectContactMutationData = {
  ref: string;
  status: ProjectContactStatus;
  data?: StandardPaginationParams;
};
export const putProjectContactMutationQuery = (projectRef: string) => {
  const queryClient = useQueryClient();
  const mutationFn = async ({ ref, status }: ProjectContactMutationData) => {
    await api.put(`/engagements/projects/${projectRef}/contacts/${ref}`, { status });
  };
  return createMutation({
    mutationFn,
    onSettled: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: QUERY_KEYS.CONTACTS_ALL }),
        queryClient.invalidateQueries({
          queryKey: [...QUERY_KEYS.PROJECTS_DETAILS, projectRef, 'submissions']
        })
      ]);
    },
    onSuccess: async (_, variables) => {
      const data = queryClient.getQueryData<ContactResponseData>([
        ...QUERY_KEYS.PROJECTS_CONTACTS,
        projectRef,
        variables.data
      ]);
      if (data) {
        queryClient.setQueryData([...QUERY_KEYS.PROJECTS_CONTACTS, projectRef, variables.data], {
          total_count: data.total_count,
          items: data.items?.map((item: Contact) =>
            item.ref === variables.ref ? { ...item, project_status: variables.status } : item
          )
        });
      } else {
        await Promise.all([
          queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PROJECTS_CONTACTS }),
          queryClient.invalidateQueries({
            queryKey: [...QUERY_KEYS.PROJECTS_DETAILS, projectRef]
          }),
          queryClient.invalidateQueries({
            queryKey: [...QUERY_KEYS.PROJECTS_SHORTLIST, projectRef]
          })
        ]);
      }
    },
    mutationKey: [...QUERY_KEYS.PROJECTS_CONTACTS, projectRef]
  });
};

type Optional<Type> = {
  [Property in keyof Type]+?: Type[Property];
};

export const updateProjectQuery = () => {
  const queryClient = useQueryClient();
  return createMutation<
    void,
    Error | ApiError,
    {
      ref: string;
      data: Optional<Project>;
    }
  >({
    mutationFn: ({ ref, data }) => api.patch<void>(`/engagements/projects/${ref}`, data),
    onSettled: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.PROJECTS_DETAILS
        }),
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.PROJECTS_ALL
        }),
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.PROJECTS_AVAILABLE
        }),
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.PROJECTS_PUBLIC
        })
      ]);
    }
  });
};

export const createProjectQuery = () => {
  const queryClient = useQueryClient();
  return createMutation<Project, unknown, CreateProjectPojo>({
    mutationFn: (data: CreateProjectPojo) => api.post<Project>('/engagements/projects', data),
    onSettled: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.PROJECTS_ALL
        }),
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.PROJECTS_AVAILABLE
        }),
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.PROJECTS_PUBLIC
        })
      ]);
    },
    mutationKey: QUERY_KEYS.PROJECTS_ALL
  });
};

export const markSubmissionsAsReadMutationQuery = (projectRef: string) => {
  return createMutation<void, unknown, string[]>({
    mutationFn: (submission_refs) =>
      api.post<void>(`/engagements/projects/${projectRef}/submission-reads`, { submission_refs })
  });
};

type ShortlistResponseData = {
  items: ShortlistEntry[];
  total_count: number;
};
export const getProjectShortlistQuery = (ref: string, data: StandardPaginationParams) =>
  createQuery({
    queryKey: [...QUERY_KEYS.PROJECTS_SHORTLIST, ref, data],
    queryFn: async () => {
      const qs = toQueryString(data);
      const response = await api.get<ShortlistResponseData>(
        `/engagements/projects/${ref}/shortlist?${qs}`
      );
      return response;
    }
  });

type GetProjectRecommendationsQueryProps = {
  ref?: string;
};
export const getProjectRecommendationsQuery = ({ ref }: GetProjectRecommendationsQueryProps) =>
  createQuery<ContributorRecommendationResult[], ApiError>({
    queryKey: [...QUERY_KEYS.PROJECTS_DETAILS, ref, 'recommendations'],
    queryFn: async () =>
      api.get<ContributorRecommendationResult[]>(`/engagements/projects/${ref}/recommendations`),
    enabled: !!ref
  });

export const getProjectsStatisticsQuery = (enabled: boolean = true) =>
  createQuery({
    queryKey: QUERY_KEYS.PROJECTS_STATISTICS,
    queryFn: async () => {
      return api.get<{
        invite_count_this_month: number;
      }>(`/engagements/projects/statistics`);
    },
    enabled
  });
