<script lang="ts">
  import { PROJECT_CONTACT_STATUSES, type ProjectContactStatus } from '$lib/constants';
  import LoadingButton from '$lib/components/LoadingButton.svelte';
  import { Group, Label, Icon } from '@smui/button';
  import {
    deleteProjectContributorMutation,
    putProjectContributor
  } from '$lib/queries/contributors';
  import { SearchContexts } from '$lib/components/Search/constants';
  import { getContext } from 'svelte';
  import * as auth from '$lib/auth';
  import CustomTooltip from '$lib/components/CustomTooltip.svelte';
  import { statusDescriptionTooltips } from '$lib/components/Projects/constants';

  export let contributorRef: string;
  export let projectRef: string | undefined;
  export let contributionStatus: ProjectContactStatus | undefined = undefined;
  export let disabled: boolean = false;
  const nonProjectFnShortlist = getContext<(ref: string) => void>(
    SearchContexts.ShortlistNoProjectFunction
  );
  const nonProjectFnInvite = getContext<(ref: string) => void>(
    SearchContexts.InviteNoProjectFunction
  );

  const contributorMutation = putProjectContributor(projectRef ?? '');
  const deleteContributorMutation = deleteProjectContributorMutation(projectRef ?? '');

  $: canUnShortlist = contributionStatus === 'shortlisted';
  let menuOpen = contributionStatus === 'shortlisted';

  const stateChange = (status: ProjectContactStatus) => {
    if (!auth.isLoggedIn()) {
      window.location.href = `/login?next=${window.location.pathname}`;
    } else if (!projectRef) {
      if (status === 'invited') {
        nonProjectFnInvite(contributorRef);
      } else {
        nonProjectFnShortlist(contributorRef);
      }
    } else {
      $contributorMutation.mutate({ ref: contributorRef, status });
    }
  };

  const unShortlist = () => {
    $deleteContributorMutation.mutate(contributorRef, {
      onSuccess: () => {
        menuOpen = false;
      }
    });
  };
  const doShortlist = () => {
    if (canUnShortlist) {
      unShortlist();
    } else {
      stateChange('shortlisted');
    }
  };
  $: inviteTooltipContent = disabled
    ? "This user cannot be invited right now as they're not currently accepting new projects."
    : "Invite a user to contribute to this project, we'll notify them so they can take a look.";

  $: shortlistTooltip = disabled
    ? "This user cannot be shortlisted right now as they're not currently accepting new projects."
    : canUnShortlist
    ? statusDescriptionTooltips.shortlisted
    : "Shortlist a user to come back to them later, this won't trigger any invites to your project.";

  $: loading = $contributorMutation.isPending || $deleteContributorMutation.isPending;
</script>

{#if contributionStatus && contributionStatus !== 'shortlisted'}
  <CustomTooltip content={statusDescriptionTooltips[contributionStatus]}>
    <LoadingButton
      style="break-inside: avoid; width: 100%"
      loading={false}
      class="d-block mb-2"
      variant="raised"
      disabled
      {...$$restProps}
      >{PROJECT_CONTACT_STATUSES[contributionStatus]}
    </LoadingButton>
  </CustomTooltip>
{:else}
  <Group variant="raised" style="width:100%" {...$$restProps}>
    <LoadingButton
      on:click={() => stateChange('invited')}
      loading={false}
      variant="raised"
      {disabled}
      class="flex-grow-1"
    >
      <CustomTooltip content={inviteTooltipContent}>
        <Label>+Invite</Label>
      </CustomTooltip>
    </LoadingButton>

    {#if projectRef}
      <LoadingButton
        loading={false}
        on:click={(e) => {
          menuOpen = !menuOpen;
        }}
        variant="raised"
        style="padding: 0; min-width: 36px;"
      >
        {#if menuOpen}
          <Icon class="material-icons" style="margin: 0;">arrow_drop_up</Icon>
        {:else}
          <Icon class="material-icons" style="margin: 0;">arrow_drop_down</Icon>
        {/if}
      </LoadingButton>
    {/if}
  </Group>
  {#if menuOpen}
    <CustomTooltip content={shortlistTooltip}>
      <LoadingButton
        on:click={doShortlist}
        style="height: auto; min-height:2.5em; margin-top: .25em; width:100%"
        variant="outlined"
        {loading}
        {disabled}>{canUnShortlist ? 'Un-Shortlist' : '+Shortlist'}</LoadingButton
      >
    </CustomTooltip>
  {/if}
{/if}
