<script lang="ts">
  import MainBorder from '$lib/components/MainBorder.svelte';
  import FieldBlock from '$lib/components/FieldBlock.svelte';
  import { toMachineDate, toReadableDate } from '$lib/utils';
  import ErrorChunk from '$lib/components/ErrorChunk.svelte';
  import StretchyTextArea from '$lib/components/StretchyTextArea.svelte';
  import PageTitle from '$lib/components/PageTitle.svelte';
  import { ApiError } from '$lib/api/errors';
  import {
    getContributionDetails,
    sendProjectContributionMessage,
    setContributionStatus,
    submitContribution
  } from '$lib/queries/contributions';
  import { capitalise } from '$lib/utils.js';
  import { PROJECT_CONTACT_STATUSES, PROJECT_STATUSES } from '$lib/constants';
  import LoadingButton from '$lib/components/LoadingButton.svelte';
  import { showErrorToast, showSuccessToast } from '$lib/components/Toaster/stores';
  import Dialog, { Actions, Content, Title } from '@smui/dialog';
  import { Label } from '@smui/button';
  import MessageThread from '$lib/components/MessageThread.svelte';
  import { goto } from '$app/navigation';
  import { getMyUserData } from '$lib/queries/users';
  import { getPronounOptions } from '$lib/queries/common';
  import FormField from '@smui/form-field';
  import Checkbox from '@smui/checkbox';
  import DeclineModal from '$lib/components/DeclineModal.svelte';
  import { Icon } from '@smui/icon-button';
  import DeletedIfNull from '$lib/components/DeletedIfNull.svelte';
  import ExternalLink from '$lib/components/ExternalLink.svelte';
  import { getProjectFieldInfo } from '$lib/project-utils';

  export let data;
  const { ref } = data.params;

  const submittableStatuses = new Set(['invited', 'accepted']);
  const submittableProjectStatuses: Set<string | undefined> = new Set([
    'open',
    'collecting_responses'
  ]);

  let errorStates: Record<string, boolean> = {};
  let submission = '';
  let chatModalOpen: boolean = false;
  let declineModalOpen: boolean = false;
  let hideCompanyName: boolean = false;

  const myUserData = getMyUserData();
  const pronounOptions = getPronounOptions();
  const query = getContributionDetails(ref);
  const setStatusMutation = setContributionStatus(ref);
  const submitContributionMutation = submitContribution(ref);
  const sendProjectContributionMessageMutation = sendProjectContributionMessage(ref);

  $: loading = $query.isLoading || $myUserData.isLoading || $pronounOptions.isLoading;
  $: notFound = $query.isError && $query.error instanceof ApiError && $query.error.status === 404;
  $: projectCanBeSubmittedTo =
    $query.data && submittableProjectStatuses.has($query.data?.project?.status);
  $: deadlineHasPassed =
    $query.data?.project?.deadline && $query.data.project?.deadline < toMachineDate(new Date());
  $: canSubmit =
    $query.data?.status &&
    submittableStatuses.has($query.data?.status) &&
    !deadlineHasPassed &&
    projectCanBeSubmittedTo;
  $: hideSubmissionBox =
    $query.isError ||
    $query.data?.project?.type !== 'article' ||
    $query.data?.status === 'retracted' ||
    $query.data?.status === 'contributor_declined' ||
    ($query.data?.status === 'media_declined' && !$query.data?.submission);

  $: canAccept = $query.data?.status === 'invited' && !deadlineHasPassed;
  $: canQuestion =
    $query.data?.status === 'invited' ||
    $query.data?.status === 'accepted' ||
    $query.data?.status === 'submitted' ||
    $query.data?.status === 'approved';
  $: canArrangeEventOrPodcast =
    ($query.data?.project?.type === 'podcast' || $query.data?.project?.type === 'event') &&
    ($query.data?.status === 'accepted' || $query.data?.status === 'approved');
  $: canDecline =
    ($query.data?.status === 'invited' || $query.data?.status === 'accepted') && !deadlineHasPassed;

  const onSubmit = (e: Event) => {
    e.preventDefault();
    if (!submission) {
      showErrorToast(new Error("Submission can't be empty"));
      errorStates = { submission: true };
      return;
    }

    $submitContributionMutation.mutate(
      { submission, hide_company_name: hideCompanyName },
      {
        onError(error) {
          showErrorToast(error);
          errorStates = { submission: true };
        },
        onSuccess: () => {
          showSuccessToast('Submission successful');
          goto('/');
        }
      }
    );
  };

  const openMessageWindow = (e: Event) => {
    e.preventDefault();
    chatModalOpen = true;
  };

  const closeMessageModal = (e?: Event) => {
    e?.preventDefault();
    chatModalOpen = false;
  };
  $: projectFieldInfo = getProjectFieldInfo($query.data?.project?.type);
</script>

<PageTitle name="Contribution submission" />

<MainBorder backButtonTitle="Back to requests" backButton="/requests">
  <FieldBlock {loading}>
    {#if notFound}
      <h3 class="mb-3">Not found</h3>
      <p>
        We couldn't find what you were looking to add a submission for 😬. Maybe recheck the url, or
        if not please reach out to the person who sent it to you to get you an updated one.
      </p>
    {:else if $query.error instanceof ApiError && $query.error?.status < 500}
      <h3 class="mb-3">{$query.error.title || 'Uh oh'}</h3>
      <p>
        {$query.error.message}
      </p>
    {:else if $query.isError}
      <ErrorChunk />
    {:else}
      <div class="row">
        <div class="col">
          <h3>
            <DeletedIfNull data={$query.data?.project?.name} />
          </h3>
          {#if $query.data?.project?.deadline}
            <p>
              <strong>{projectFieldInfo.deadlineLabel}:</strong>
              {toReadableDate($query.data?.project?.deadline)}
            </p>
          {/if}
          <p>
            <strong>{projectFieldInfo.outletLabel}:</strong>
            <DeletedIfNull data={$query.data?.project?.outlet} />
          </p>
          {#if $query.data?.project?.outlet_link}
            <p>
              <strong>{projectFieldInfo.outletLinkLabel}:</strong>
              <ExternalLink href={$query.data.project.outlet_link}
                >{$query.data.project.outlet_link}</ExternalLink
              >
            </p>
          {/if}
          {#if $query.data?.project?.outlet_viewership}<p>
              <strong
                >{projectFieldInfo.outletViewershipLabel}
                :</strong
              >
              {$query.data.project?.outlet_viewership}
            </p>
          {/if}
          {#if $query.data?.project?.meeting_link}
            <p>
              <ExternalLink href={$query.data.project?.meeting_link}
                >{projectFieldInfo.meetingLinkLabel}</ExternalLink
              >
            </p>
          {/if}
          {#if $query.data?.project?.type}
            <p>
              <strong>Type:</strong>
              {capitalise($query.data.project?.type)}
            </p>
          {/if}
          <p style="white-space: pre-line">{$query.data?.project?.description ?? ''}</p>
        </div>
      </div>
      <div class="d-flex mt-3">
        <a
          href={!!$query.data?.project?.user__ref
            ? `/profiles/${$query.data?.project?.user__ref}`
            : '#'}
        >
          {#if $query.data?.project?.user__avatar_url}
            <img
              class="avatar"
              src={$query.data?.project?.user__avatar_url}
              alt="Your avatar used to show your profile on the site."
              height="75"
              width="75"
            />
          {:else}
            <div class="avatar">
              <Icon class="material-icons" style="font-size:85px;color:white;">person</Icon>
            </div>
          {/if}
        </a>
        <div>
          <p>
            <strong>Name:</strong>
            <DeletedIfNull data={$query.data?.project?.user__name} />
          </p>
          {#if $query.data?.project?.user__company_name}
            <p>
              <strong>Company:</strong>
              {$query.data?.project?.user__company_name}
            </p>
          {/if}
          <p>
            <strong>Job Title:</strong>
            <DeletedIfNull data={$query.data?.project?.user__job_title} />
          </p>
          {#if $query.data?.project?.user__company_name}
            <p><strong>Company Name:</strong> {$query.data?.project?.user__company_name}</p>
          {/if}
        </div>
      </div>
      <hr class="mt-4" />
      <div class="text-center">
        <p>
          <strong>Status:</strong>
          {PROJECT_CONTACT_STATUSES[$query.data?.status || 'invited']}
        </p>
        {#if $query.data?.decline_reason && ($query.data?.status === 'contributor_declined' || $query.data?.status === 'media_declined')}
          <p>
            <strong>Reason given:</strong>
            {$query.data?.decline_reason}
          </p>
        {/if}
        {#if $query.data?.project?.ref}
          {#if canAccept}
            <LoadingButton
              loading={false}
              on:click={() => $setStatusMutation.mutate({ status: 'accepted' })}
              >✅ Accept Invitation
            </LoadingButton>
          {/if}
          {#if canQuestion && !canArrangeEventOrPodcast}
            <LoadingButton loading={false} on:click={openMessageWindow}
              >💬 Ask a question
            </LoadingButton>
          {/if}
          {#if canDecline}
            <LoadingButton
              loading={false}
              on:click={() => {
                declineModalOpen = true;
              }}
              >🚫 Decline Invitation
            </LoadingButton>
          {/if}
        {/if}
      </div>
    {/if}
  </FieldBlock>

  <form on:submit={onSubmit}>
    <FieldBlock {loading} hidden={hideSubmissionBox}>
      {#if canSubmit}
        <h3 class="pt-3">About You</h3>
        <p class="pt-2">
          The below information is visible to the project owner and will be used to reference you in
          the article, podcast, or event. To change how you are referred to, update your <a
            href="/settings/profile">contributor profile</a
          >.
        </p>
        <p><strong>Name:</strong> {$myUserData.data?.first_name} {$myUserData.data?.last_name}</p>
        {#if $myUserData.data?.pronouns && $pronounOptions.data}
          <p><strong>Pronouns:</strong> {$pronounOptions.data[$myUserData.data?.pronouns]}</p>
        {/if}
        <p><strong>Job title:</strong> {$myUserData.data?.job_title}</p>
        {#if $myUserData.data?.company_name}
          <p style="margin-bottom: 0.5em">
            <strong>Company name:</strong>
            {$myUserData.data?.company_name}
          </p>
          <FormField class="checkbox-wrapper" style="left: -10px; position: relative">
            <Checkbox bind:checked={hideCompanyName} value={true} />
            <span slot="label">Do not reference my company name as part of this submission</span>
          </FormField>
        {/if}
        <h3 class="pt-4">Your submission</h3>
        <StretchyTextArea
          id="submission"
          bind:value={submission}
          invalid={!!errorStates.submission}
          label="Your submission goes here"
          maxLength={100000}
          showWordCount
        />
      {:else if deadlineHasPassed && !$query.data?.submission}
        <h3>Deadline passed</h3>
        <p>
          The deadline for this project has passed. You can no longer submit a contribution for this
          project.
        </p>
      {:else if !projectCanBeSubmittedTo}
        <h3>
          Project
          <DeletedIfNull data={PROJECT_STATUSES[$query.data?.project?.status]} />
        </h3>
        <p>This project is not accepting any submissions.</p>
      {/if}
      {#if $query.data?.submission}
        <h3 class="pt-3">Your submission</h3>
        <p style="white-space: pre-line">{$query.data?.submission}</p>
      {/if}
      {#if canSubmit}
        <div class="text-center mt-5 mb-3" hidden={$query.isError}>
          <LoadingButton type="submit" variant="raised" {loading}>Submit</LoadingButton>
        </div>
      {/if}
    </FieldBlock>
  </form>
  <FieldBlock hidden={!canArrangeEventOrPodcast}>
    <div class="text-center mt-3 mb-3" hidden={$query.isError}>
      <LoadingButton
        type="submit"
        variant="raised"
        {loading}
        on:click={() => {
          chatModalOpen = true;
        }}
        >{projectFieldInfo.arrangeLabel}
      </LoadingButton>
    </div>
  </FieldBlock>
</MainBorder>

<Dialog
  bind:open={chatModalOpen}
  aria-labelledby="Ask a Question"
  aria-describedby="Ask a question about the request"
  surface$style="width: 650px; max-width: calc(100vw - 32px);"
>
  <Title>Message {$query.data?.project?.user__name}</Title>
  <Content>
    {#if chatModalOpen && $query.data}
      <MessageThread
        userRef={$query.data.project?.user__ref}
        userName={$query.data.project?.user__name}
        mutation={sendProjectContributionMessageMutation}
      />
    {/if}
  </Content>
  <Actions>
    <LoadingButton
      loading={false}
      disabled={$sendProjectContributionMessageMutation.isPending}
      on:click={closeMessageModal}
    >
      <Label>Close</Label>
    </LoadingButton>
  </Actions>
</Dialog>

<DeclineModal
  bind:open={declineModalOpen}
  mutation={setStatusMutation}
  status="contributor_declined"
/>

<style>
  p {
    margin-bottom: 0.5em;
    margin-top: 0.5em;
  }

  .avatar {
    overflow: hidden;
    background-color: #e0e0e0;
    width: 85px;
    height: 85px;
    margin: 1.5em 1.5em 1em 0.5em;
  }
</style>
