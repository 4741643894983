import { del } from './api';
import * as auth from './auth';
import type { FormOption } from '$lib/types';
import DOMPurify from 'dompurify';
import linkifyStr from 'linkify-string';

export const logout = async () => {
  try {
    await del('/auth/session');
  } finally {
    auth.logout();
  }
};
export const getSvgTag = (n: string) => {
  switch (n) {
    case 'twitter':
    case 'linkedin':
      return n;
    case 'blog':
      return 'rss_feed';
    case 'newsletter':
      return 'feed';
    default:
      return 'public';
  }
};
export const getSocialTitle = (n: string) => {
  switch (n) {
    case 'twitter':
    case 'newsletter':
    case 'blog':
      return `${n.charAt(0).toUpperCase()}${n.slice(1)}`;
    case 'linkedin':
      return 'LinkedIn';
    default:
      return n;
  }
};

export const toReadableDate = (date?: string | null) => {
  if (!date) {
    return '';
  }
  return new Date(date).toLocaleDateString('en-GB', {
    month: 'short',
    year: 'numeric',
    day: 'numeric'
  });
};

export const toReadableDateTime = (date?: string) => {
  if (!date) {
    return '';
  }
  return new Date(date).toLocaleDateString('en-GB', {
    month: 'short',
    year: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  });
};
const prePadZero = (n: number) => {
  return n < 10 ? `0${n}` : `${n}`;
};
export const toMachineDate = (d: Date): string => {
  return `${d.getUTCFullYear()}-${prePadZero(d.getUTCMonth() + 1)}-${prePadZero(d.getUTCDate())}`;
};

export const toQueryString = (obj: Record<string, string | null | undefined | number>): string => {
  const objectToQueryString = Object.entries(obj).reduce((acc, [key, value]) => {
    if (value !== undefined && value !== null) {
      acc[key] = value.toString();
    }
    return acc;
  }, {} as Record<string, string>);
  return new URLSearchParams(objectToQueryString).toString();
};

export const capitalise = (s?: string): string =>
  s ? `${s.charAt(0).toUpperCase()}${s.slice(1).replaceAll('_', ' ')}` : '';

export const formOptionsToMap = (formOptions: FormOption[]) =>
  formOptions.reduce((acc, { label, value }) => {
    acc[value] = label;
    return acc;
  }, {} as Record<string, string>);

export const debounce = (fn: (...args: any[]) => void, delay: number) => {
  let timeout: any;
  return (...args: any[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};
export const formatMessageContent = (unsafeContent: string): string => {
  const content = DOMPurify.sanitize(unsafeContent, { ALLOWED_TAGS: [] });
  return linkifyStr(content, { target: '_blank', rel: 'nofollow noopener noreferrer ugc' });
};

const months: Record<number, string> = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December'
};
export const nextMonthName = (): string => {
  const dateInOneMonth = new Date();
  dateInOneMonth.setMonth(new Date().getMonth() + 1);
  return months[dateInOneMonth.getMonth()] ?? 'next month';
};
