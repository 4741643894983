<script lang="ts">
  import Button, { Label } from '@smui/button';
  import Dialog, { Actions, Content, Title } from '@smui/dialog';
  import LoadingButton from '$lib/components/LoadingButton.svelte';
  import Cropper from 'cropperjs';
  import 'cropperjs/src/css/cropper.css';
  import { showErrorToast } from '$lib/components/Toaster/stores';
  import {
    croppableAvatarFileStore,
    croppableAvatarMutationPending
  } from '$lib/components/Images/utils';
  import { myProfilePhotoMutation } from '$lib/queries/users';

  const profilePhotoMutation = myProfilePhotoMutation();
  let cropper: any;
  let image: HTMLImageElement;
  let open = false;

  const reader = new FileReader();
  reader.onload = (e) => {
    open = true;
    if (image) {
      image.src = e.target?.result?.toString() || '';
      cropper = new Cropper(image, {
        aspectRatio: 1,
        viewMode: 1
      });
    }
  };

  croppableAvatarFileStore.subscribe((uploadFile?: File) => {
    if (uploadFile) {
      reader.readAsDataURL(uploadFile);
    }
  });

  const closeCropper = () => {
    croppableAvatarFileStore.set(undefined);
    image.src = '';
    open = false;
    if (cropper) {
      cropper.destroy();
      cropper = null;
    }
  };

  const storeFile = async () => {
    croppableAvatarMutationPending.set(true);
    const canvas = cropper.getCroppedCanvas({
      minWidth: 500,
      minHeight: 500
    });
    canvas.toBlob((b: Blob) => {
      open = false;
      $profilePhotoMutation.mutate(new File([b], 'avatar.png'), {
        onError: (e) => {
          showErrorToast(e);
        },
        onSettled: () => {
          closeCropper();
          croppableAvatarMutationPending.set(false);
        }
      });
    }, 'image/png');
  };

  const doClose = (e: any) => {
    if (e?.detail?.action === 'upload') {
      storeFile();
    } else {
      closeCropper();
    }
  };
</script>

<Dialog
  bind:open
  autoStackButtons={false}
  on:SMUIDialog:closed={doClose}
  surface$style="width:850px; max-width: calc(100vw - 32px);height: 850px; max-width: calc(100vh - 100px);"
>
  <Title>Edit your avatar</Title>
  <Content>
    <div style="display: block;height: 90%; margin:0 auto">
      <img src="" bind:this={image} alt="A rendering of this avatar being uploaded." />
    </div>
  </Content>
  <Actions>
    <LoadingButton
      on:click={(e) => e.preventDefault()}
      action="upload"
      loading={$profilePhotoMutation.isPending}
      variant="raised"
      defaultAction
    >
      <Label>Upload</Label>
    </LoadingButton>
    <Button variant="outlined" on:click={(e) => e.preventDefault()}>close</Button>
  </Actions>
</Dialog>
