<script lang="ts">
  import FieldBlock from '$lib/components/FieldBlock.svelte';
  import PageTitle from '$lib/components/PageTitle.svelte';
  import { onClickAndKeydown } from '$lib/directives';

  import IconButton, { Icon } from '@smui/icon-button';
  import Badge from '@smui-extra/badge';
  import * as auth from '$lib/auth';
  import { capitalise, toReadableDateTime } from '$lib/utils';
  import {
    getMyNotificationsQuery,
    markNotificationAsReadMutation,
    type Notification
  } from '$lib/queries/notifications';
  import ErrorChunk from '$lib/components/ErrorChunk.svelte';
  import { onMount } from 'svelte';
  import { goto } from '$app/navigation';
  import { type NOTIFICATION_TYPE } from '$lib/constants';
  import Button from '@smui/button';

  let previousLastNotificationRefs: Array<undefined | string> = [];
  let lastNotificationRef: string | undefined = undefined;
  $: myNotificationsQuery = getMyNotificationsQuery(
    { last_ref: lastNotificationRef },
    auth.isLoggedIn()
  );
  const mutation = markNotificationAsReadMutation();

  let markAsReadTimeoutId: any | undefined = undefined;
  let firstNotificationLoaded = false;
  $: if (!firstNotificationLoaded && $myNotificationsQuery.data?.items.length) {
    firstNotificationLoaded = true;
    const firstItem = $myNotificationsQuery.data.items[0];
    markAsReadTimeoutId = setTimeout(() => {
      $mutation.mutate(firstItem.ref);
    }, 3500);
  }

  onMount(() => {
    if (!auth.isLoggedIn()) {
      window.location.href = '/login?next=/notifications';
    }
    return () => {
      if (markAsReadTimeoutId) {
        clearTimeout(markAsReadTimeoutId);
      }
    };
  });

  const nextPageNotifications = () => {
    const ref = $myNotificationsQuery.data?.items.slice(-1)[0].ref;
    if (!ref) {
      return;
    }
    previousLastNotificationRefs = [...previousLastNotificationRefs, lastNotificationRef];
    lastNotificationRef = ref;
  };

  const prevPageNotifications = () => {
    lastNotificationRef = previousLastNotificationRefs.pop();
    previousLastNotificationRefs = previousLastNotificationRefs;
  };

  const clickNotification = (notification: Notification) => {
    $mutation.mutate(notification.ref);
    if (notification.target) {
      goto(notification.target);
    }
  };

  const defaultIcon = 'edit_note';

  const notificationIconsMap: Partial<Record<NOTIFICATION_TYPE, string>> = {
    contribution_deadline_approaching: 'schedule',
    contribution_deadline_soonish: 'schedule',
    contributor_project_accepted: 'handshake',
    contributor_project_invited: 'campaign',
    contributor_project_retracted: 'cancel',
    contributor_project_contributor_declined: 'cancel',
    contributor_project_media_declined: 'cancel',
    contributor_project_submitted: 'star_border',
    contributor_project_approved: 'star_border',
    project_deadline_approaching: 'schedule'
  };

  const metaToList = (
    meta: Record<string, any> | undefined
  ): Array<{ key: string; title: string }> => {
    if (!meta) {
      return [];
    }
    return Object.entries(meta).map(([key, title]) => ({ key, title }));
  };
</script>

<PageTitle name="Notifications" />

<div class="row">
  <div class="offset-md-3 col-md-6">
    <FieldBlock>
      <h3>Notifications</h3>
    </FieldBlock>
  </div>
</div>
<div class="row">
  <div class="offset-md-3 col-md-6">
    <FieldBlock loading={$myNotificationsQuery.isLoading}>
      <div class="conversation-list">
        {#each $myNotificationsQuery.data?.items || [] as item (item.ref)}
          <div
            class="notification gap-2"
            class:has-link={!!item.target}
            use:onClickAndKeydown={() => clickNotification(item)}
          >
            <div class="d-flex">
              <div class="notification-icon">
                <Icon class="material-icons" style="font-size:42px;color:rgb(121, 40, 202);"
                  >{notificationIconsMap[item.type] ?? defaultIcon}
                </Icon>
                {#if !item.is_read}
                  <Badge
                    style="background-color:rgb(121, 40, 202);height: 9px !important;width: 9px !important;min-height: 0 !important;min-width: 0 !important;left: -9px !important;top:18px !important;"
                    position="middle"
                    aria-label="unread notification count"
                    align="middle-start"
                  />
                {/if}
              </div>
              <div class="d-flex justify-content-start flex-column">
                <p class="text-left mb-0">{item.content}</p>
                {#each metaToList(item.meta) as metaItem}
                  <p class="created-at mb-0">
                    <strong>{capitalise(metaItem.key)}:</strong>
                    {metaItem.title}
                  </p>
                {/each}
                <p class="created-at mt-3">{toReadableDateTime(item.created_at)}</p>
              </div>
            </div>

            {#if !!item.target}
              <div>
                <Button
                  class="d-none d-md-flex"
                  style="height: auto;min-height: 2.5em;min-width: 7em;padding: 0;align-items: center;margin: auto"
                  variant="outlined"
                  href={item.target}
                  >See more
                </Button>
              </div>
            {/if}
          </div>
        {:else}
          {#if $myNotificationsQuery.isError}
            <ErrorChunk />
          {:else}
            <div class="notification">
              <p>You don't have any notifications yet. Go get contributing!</p>
            </div>
          {/if}
        {/each}
      </div>
      {#if lastNotificationRef || $myNotificationsQuery.data?.has_more}
        <nav
          aria-label="Page navigation example"
          class="d-flex justify-content-center align-items-center"
        >
          <IconButton
            class="material-icons"
            action="prev-page"
            title="Prev page"
            on:click={prevPageNotifications}
            disabled={!previousLastNotificationRefs.length}
          >
            chevron_left
          </IconButton>
          <IconButton
            class="material-icons"
            action="next-page"
            title="Next page"
            on:click={nextPageNotifications}
            disabled={!$myNotificationsQuery.data?.has_more}
          >
            chevron_right
          </IconButton>
        </nav>
      {/if}
    </FieldBlock>
  </div>
</div>

<style lang="scss">
  .notification {
    margin-top: 1em;
    border-bottom: 1px solid #ddd;

    display: flex;
    justify-content: space-between;

    &.has-link {
      cursor: pointer;
    }
  }

  .conversation-list .notification:last-child {
    border-bottom: none;
  }

  .created-at {
    margin-top: 0;
    font-size: 0.8rem;
    color: #666;
  }

  .notification-icon {
    position: relative;
    overflow: visible;
    width: 35px;
    margin: 0.25em 1.5em 1em 0.5em;
    border-radius: 99999px;
  }
</style>
