<script lang="ts">
  import MainBorder from '$lib/components/MainBorder.svelte';
  import FieldBlock from '$lib/components/FieldBlock.svelte';
  import { getProjectQuery } from '$lib/queries/projects';
  import SearchInput from '$lib/components/SearchInput.svelte';
  import { page } from '$app/stores';
  import { goto } from '$app/navigation';
  import Card, { Content } from '@smui/card';
  import IconButton, { Icon } from '@smui/icon-button';
  import {
    ALGOLIA_APPLICATION_ID,
    type ProjectContactStatus,
    SOCIAL_FORM_OPTIONS
  } from '$lib/constants';
  import { getSearch, getSearchToken } from '$lib/queries/search';
  import ErrorChunk from '$lib/components/ErrorChunk.svelte';
  import Navigation from '$lib/components/Search/Navigation.svelte';
  import Accordion from '@smui-extra/accordion';
  import { fieldInfoToIdentifiesAsMap, getFieldInfo } from '$lib/queries/common';
  import { onClickAndKeydown } from '$lib/directives';
  import Button, { Label } from '@smui/button';
  import Filter from '$lib/components/Search/Filter.svelte';
  import SelectFilter from '$lib/components/Search/SelectFilter.svelte';
  import type { ContributorSearchResult } from '$lib/types';
  import { getMyContributorProfile } from '$lib/queries/contributors';
  import * as cache from '$lib/queries/cache';
  import Avatar from '$lib/components/Images/Avatar.svelte';
  import { setContext } from 'svelte';
  import { SearchContexts } from '$lib/components/Search/constants';
  import ProjectAddModal from '$lib/components/Projects/ProjectAddModal.svelte';
  import InteractButton from '$lib/components/Projects/InteractButton.svelte';
  import Chip, { Set, Text } from '@smui/chips';

  export let projectRef: string | undefined = undefined;

  const searchFiltersCacheKey = 'contributorSearchFilters';

  const getSearchParams = () => $page.url?.searchParams || new URLSearchParams();

  let showFiltersToggle = false;
  let hitsPerPage = 25;
  let currentPage = Number(getSearchParams().get('page')) || 0;
  let firstRecordCount = 0;
  let query = getSearchParams().get('q') ?? '';
  const modalData: {
    open: boolean;
    contributorRef: string;
    action: 'shortlist' | 'invite';
  } = {
    open: false,
    contributorRef: '',
    action: 'shortlist'
  };
  $: firstRecordCount = currentPage * hitsPerPage + 1;

  const panels: Record<string, boolean> = {
    contribution_types: false,
    company_size: false,
    years_of_experience: false,
    contributor_experience: false,
    identifies_as_list: false,
    follower_count_list: false,
    country: false
  };
  const searchFilters: Record<string, string[]> = {
    contribution_types: [],
    company_size: [],
    years_of_experience: [],
    contributor_experience: [],
    identifies_as_list: [],
    follower_count_list: []
  };
  let countryFilter: { value: string; label: string } | null = null;
  let submittedFilters: Record<string, string[]> = {
    contribution_types: [],
    company_size: [],
    years_of_experience: [],
    contributor_experience: [],
    identifies_as_list: [],
    follower_count_list: [],
    country: []
  };
  let filtersDirty = false;

  const cachedSearchData = cache.getData(searchFiltersCacheKey);
  if (cachedSearchData) {
    Object.entries(cachedSearchData).forEach(([key, value]) => {
      submittedFilters[key] = value;
      if (key === 'country') {
        if (value.length) {
          panels['country'] = true;
          countryFilter = { value: value[0], label: cachedSearchData.countryLabel };
        }
      } else {
        panels[key] = value.length > 0;
        searchFilters[key] = value;
      }
    });
  }

  const fieldInfoQuery = getFieldInfo();
  $: identifiesAsMap = fieldInfoToIdentifiesAsMap($fieldInfoQuery.data);
  $: identifiesAsOptions = Object.entries($identifiesAsMap.data ?? {}).map(([key, value]) => ({
    value: key,
    label: value,
    name: value
  }));
  let countryList: {
    value: string;
    label: string;
  }[] = [];
  $: countryList = $fieldInfoQuery.data
    ? Object.entries($fieldInfoQuery.data.countries).map(([value, label]) => ({
        value,
        label
      }))
    : [];
  const myContributorProfile = getMyContributorProfile();
  $: projectQuery = getProjectQuery(projectRef);
  const searchTokenQuery = getSearchToken();
  $: searchQuery = getSearch({
    applicationId: ALGOLIA_APPLICATION_ID,
    hitsPerPage,
    index: 'contributors',
    page: currentPage,
    projectRef,
    query,
    searchFilters: submittedFilters,
    searchToken: $searchTokenQuery?.data
  });
  $: projectContributorStatuses =
    $projectQuery.data?.shortlist.reduce((acc, curr) => {
      acc[curr.ref] = curr.status;
      return acc;
    }, {} as Record<string, ProjectContactStatus>) || {};

  let quickLoad = false;
  const doQuickLoad = () => {
    quickLoad = true;
    setTimeout(() => {
      quickLoad = false;
    }, 175);
  };

  const submit = async (val: string, page: number = 0) => {
    filtersDirty = false;
    currentPage = page;
    query = val;
    submittedFilters = {
      ...Object.entries(searchFilters).reduce((acc, [key, value]) => {
        acc[key] = [...value];
        return acc;
      }, {} as Record<string, string[]>),
      country: countryFilter ? [countryFilter.value] : []
    };
    cache.setData(searchFiltersCacheKey, {
      ...submittedFilters,
      countryLabel: countryFilter?.label
    });
    getSearchParams().set('page', page.toString());
    getSearchParams().set('q', val);
    await goto(`?${getSearchParams().toString()}`);
    doQuickLoad();
  };

  const setCurrentPage = (page: number) => {
    submit(getSearchParams().get('q') ?? '', page);
  };
  const changeFilter = () => {
    filtersDirty = true;
  };
  const clearFilters = () => {
    Object.entries(searchFilters).forEach(([key, val]) => {
      searchFilters[key] = [];
    });
    countryFilter = null;
    submit(query);
  };

  const getDetailHref = (item: ContributorSearchResult) => {
    const ref = item.objectID.split(':')[1];
    switch (item.model_type) {
      case 'contributor':
        return projectRef ? `/projects/${projectRef}/contributors/${ref}` : `/contributors/${ref}`;
    }
  };

  $: error = $projectQuery.error || $searchTokenQuery.error || $searchQuery?.error;
  $: loading = Boolean(
    Number($searchTokenQuery.isLoading) | Number($searchQuery?.isLoading) | Number(quickLoad)
  );

  const openProjectModalFn = (action: 'invite' | 'shortlist') => (ref: string) => {
    modalData.open = true;
    modalData.contributorRef = ref;
    modalData.action = action;
  };
  setContext(SearchContexts.InviteNoProjectFunction, openProjectModalFn('invite'));
  setContext(SearchContexts.ShortlistNoProjectFunction, openProjectModalFn('shortlist'));
</script>

<MainBorder full>
  <div class="row">
    <div class="col-lg-3">
      {#if projectRef}
        <div style="margin: 1.75em 0 0 .5em; width: 100%">
          <a href="/projects/{projectRef}" style="margin: 0 auto">← Back to Project </a>
        </div>
      {/if}
      <FieldBlock loading={$fieldInfoQuery.isLoading}>
        <div
          slot="title"
          use:onClickAndKeydown={() => {
            showFiltersToggle = !showFiltersToggle;
          }}
        >
          <h4 class="filters-title">
            Filters
            <IconButton slot="icon" toggle disabled class="filters-toggle">
              <Icon class="material-icons" on={!showFiltersToggle}>expand_less</Icon>
              <Icon class="material-icons" on={showFiltersToggle}>expand_more</Icon>
            </IconButton>
          </h4>
        </div>
        <div class="filter-chunk" class:toggled-on={showFiltersToggle}>
          <Accordion multiple class="mt-3">
            <Filter
              name="Contribution types"
              options={$fieldInfoQuery.data?.contribution_types ?? []}
              bind:value={searchFilters.contribution_types}
              open={panels['contribution_types']}
              change={changeFilter}
            />
            <Filter
              name="Company size"
              options={$fieldInfoQuery.data?.company_sizes ?? []}
              bind:value={searchFilters.company_size}
              open={panels['company_size']}
              change={changeFilter}
            />
            <Filter
              name="Seniority"
              options={$fieldInfoQuery.data?.years_of_experience ?? []}
              bind:value={searchFilters.years_of_experience}
              open={panels['years_of_experience']}
              change={changeFilter}
            />
            <Filter
              name="Contributor experience"
              options={$fieldInfoQuery.data?.contribution_experiences ?? []}
              bind:value={searchFilters.contributor_experience}
              open={panels['contributor_experience']}
              change={changeFilter}
            />
            <Filter
              name="Diversity"
              options={identifiesAsOptions}
              bind:value={searchFilters.identifies_as_list}
              open={panels['identifies_as_list']}
              change={changeFilter}
            />
            <Filter
              name="Influencer Level"
              options={SOCIAL_FORM_OPTIONS}
              bind:value={searchFilters.follower_count_list}
              open={panels['follower_count_list']}
              change={changeFilter}
            />
            <SelectFilter
              name="Location"
              options={countryList}
              bind:value={countryFilter}
              open={panels['country']}
              change={changeFilter}
            />
          </Accordion>
          {#if filtersDirty}
            <div class="text-center mt-3">
              <Button variant="raised" on:click={() => submit(getSearchParams().get('q') ?? '')}>
                Apply
              </Button>
            </div>
          {/if}
          <p class="mt-3 text-center clear" use:onClickAndKeydown={clearFilters}>
            Clear all filters
          </p>
        </div>
      </FieldBlock>
    </div>
    <div class="col-lg-9">
      <slot name="heading" />
      <FieldBlock {loading}>
        <div slot="title">
          <div>
            <div class="row">
              <div class="col-md-6">
                <h3>Search</h3>
                <p>Search for contributors to shortlist for your project</p>
              </div>
              <SearchInput {submit} value={query} searchDirty={filtersDirty} />
            </div>
          </div>
        </div>
        {#if error}
          <ErrorChunk />
        {:else}
          {#if $searchQuery?.data?.nbHits === 0}
            <p>
              <strong
                >We couldn't find anyone matching that criteria. Please try different filters or
                search terms.</strong
              >
            </p>
          {/if}
          {#each $searchQuery?.data?.hits ?? [] as item (item.objectID)}
            <Card style="margin-top: 1.5em">
              <Content style="display: flex; justify-content: space-between;">
                <div class="card-chunk">
                  <a href={getDetailHref(item)}>
                    <Avatar src={item.avatar_url} hasAvatar={!!item.avatar_url} />
                  </a>

                  <p class="p-info mt-3">
                    <strong>{item.first_name} {item.last_name}</strong>
                  </p>
                  {#if item.job_title}<p class="p-info">{item.job_title}</p>{/if}
                  {#if item.company_name}<p class="p-info">{item.company_name}</p>{/if}
                  <p>
                    Located in {$fieldInfoQuery.data?.countries[item.country]}
                    {#if item.city}, {item.city}{/if}
                  </p>
                  {#if item.expertise_list?.length}
                    <p class="mb-0 mt-2"><strong>Expertise:</strong></p>
                    <Set chips={item.expertise_list} let:chip nonInteractive>
                      <Chip {chip}>
                        <Text>{chip}</Text>
                      </Chip>
                    </Set>
                  {/if}
                  {#if item.model_type === 'contributor' && item.talking_points.trim() !== ''}
                    <p class="p-info mt-3"><strong>Loves to talk about</strong></p>
                    <p class="p-info">{item.talking_points}</p>
                  {/if}
                </div>
                <div class="card-chunk end">
                  <a href={getDetailHref(item)} class="mb-2" style="width: 100%">
                    <Button class="d-block" variant="raised" style="width: 100%">Profile</Button>
                  </a>
                  {#if item.objectID !== `contributor:${$myContributorProfile.data?.ref}`}
                    <InteractButton
                      contributorRef={item.objectID.split(':')[1]}
                      {projectRef}
                      contributionStatus={projectContributorStatuses[item.objectID]}
                    />
                  {/if}
                </div>
              </Content>
            </Card>
          {/each}
          {#if $searchQuery?.data?.nbHits && $searchQuery?.data?.nbHits < 10}
            <p class="search-message">
              <strong>Use different filters or search terms to find more people.</strong>
            </p>
          {/if}
          <Navigation
            {currentPage}
            {firstRecordCount}
            lastRecordCount={$searchQuery?.data?.hits?.length + firstRecordCount - 1 || 0}
            totalHits={$searchQuery?.data?.nbHits ?? 0}
            totalPages={$searchQuery?.data?.nbPages ?? 0}
            setPage={setCurrentPage}
          />
        {/if}
      </FieldBlock>
    </div>
  </div>
</MainBorder>

<ProjectAddModal {modalData} />

<style lang="scss">
  .card-chunk {
    padding: 0.5em 0.5em 0 0.5em;
    align-items: flex-start;
    align-content: flex-start;
    text-align: left;

    a {
      text-decoration: none;
    }

    &.end {
      justify-content: flex-start;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .p-info {
    margin: 0;
  }

  .search-message {
    margin-top: 1.5em;
  }

  .clear {
    cursor: pointer;
    text-decoration: underline;
  }

  .filter-chunk {
    &.toggled-on {
      display: block;
    }

    @media (min-width: 992px) {
      display: block;
    }
    display: none;
  }

  :global(.filters-toggle) {
    margin: 0;
    padding: 0;
    height: 1em;
    float: right;
    @media (min-width: 992px) {
      display: none;
    }
  }

  .filters-title {
    @media (max-width: 991px) {
      cursor: pointer;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 1em;
  }
</style>
