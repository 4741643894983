import { thirtyMinutesInMs } from '../constants';

type StorageItem<T> = {
  expiry: number;
  data: T;
};
const cachePrefix = 'aq_query_cache';

export const getData = <T extends Record<string, any>>(key: string): T | null => {
  const cacheKey = `${cachePrefix}:${key}`;
  const val = window.localStorage.getItem(cacheKey);
  if (!val) {
    return null;
  }
  const { expiry, data } = JSON.parse(val) as StorageItem<T>;
  if (expiry < Date.now()) {
    return null;
  }
  return data;
};

export const setData = (key: string, data: Record<string, any>) => {
  const cacheKey = `${cachePrefix}:${key}`;
  window.localStorage.setItem(
    cacheKey,
    JSON.stringify({
      data,
      expiry: Date.now() + thirtyMinutesInMs
    })
  );
};
